@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 220px;
    height: 251px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 250px;
    height: 286px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 250px;
    height: 286px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 300px;
    height: 343px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 300px;
    height: 343px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 330px;
    height: 377px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(-1500px);
    }

    100% {
      transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll3 {
    0% {
      transform: translateX(0);
    }

    100% {
      transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 10%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}