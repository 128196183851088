@media screen and (max-width: 499px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 100%;
    max-width: 1500px;
    perspective: 300px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 120px;
    height: 137px;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 500px) and (max-width: 767px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 90%;
    max-width: 960px;
    perspective: 300px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 768px) and (max-width: 991px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 80%;
    max-width: 960px;
    perspective: 300px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 992px) and (max-width: 1199px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 80%;
    max-width: 960px;
    perspective: 300px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 1200px) and (max-width: 1919px) {



  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 100%;
    max-width: 960px;
    perspective: 300px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 0.5%;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 1600px;
    perspective: 400px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 500px;
    height: 571px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 2000px;
    perspective: 400px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 600px;
    height: 686px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 3840px) {

 
  @keyframes carouselAnim{
    0%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    27%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    60%{
      transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    93%{
      transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      transform-origin: right center;
      z-index: 5;
    }
    100%{
      transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 2000px;
    perspective: 400px;
    perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    transform-origin: left center;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    animation-delay: -4s;
  }
  .carousel__item:last-child{
    animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 600px;
    height: 686px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}