@import url(https://fonts.cdnfonts.com/css/liquidism-part-2);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Mochiy Pop One', sans-serif;
}

a {
  text-align: center;
  text-decoration: none;
  justify-content: center;
  color: white;
}


@media screen and (max-width: 499px) {
  .team1 {
    margin-bottom: 7%;
  }
  .man {
    width: 280px;
    height: 280px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .memName {
     color: white;
    font-size: 24px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: center;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .connect2Mobile{
    display: none;
  }
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 50px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
  
  }
  
  .storyCon2Rm {
    font-size: 18px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2Mobile {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33%;
    
  }

  .connect2{
    display: none;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 50px;
    width: 180px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 17px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    text-align: center;
    margin-top: 3%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 14px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 60px;
    height: 52px;
  }
  
  .sop {
    width: 90px;
    height: 41px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 230px;
    height: 263px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 16px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 16px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 16px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 16px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 16px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 22px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 17px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .team1 {
    margin-bottom: 5%;
  }
  .man {
    width: 330px;
    height: 330px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .memName {
     color: white;
    font-size: 24px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: center;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .connect2Mobile{
    display: none;
  }
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 50px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 18px;
  
  }
  
  .storyCon2Rm {
    font-size: 18px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2Mobile {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 33.33%;
    
  }

  .connect2{
    display: none;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 25px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 26px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 70px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 50px;
    width: 180px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    text-align: center;
    margin-top: 3%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 16px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 60px;
    height: 52px;
  }
  
  .sop {
    width: 90px;
    height: 41px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 300px;
    height: 343px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 50px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 5%;
    font-size: 20px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 25px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 18px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 90%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .team1 {
    margin-bottom: 5%;
  }
  .man {
    width: 330px;
    height: 330px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
  }

  .memName {
     color: white;
    font-size: 24px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: center;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
 
  .connect2Mobile{
    display: none;
  }
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 20px;
  
  }
  
  .storyCon2Rm {
    font-size: 18px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 5%;
    padding-bottom: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 19px;
    border-radius: 50px;
    width: 200px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 18px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    text-align: center;
    margin-top: 3%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 80px;
    height: 69px;
  }
  
  .sop {
    width: 110px;
    height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 350px;
    height: 400px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 60%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  a {
    text-align: center;
    text-decoration: none;
    justify-content: center;
  }
  
  .team1 {
  }
  .man {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3%;
  }

  .memName {
     color: white;
    font-size: 18px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .teamH {
    font-family: "Visitor TT2 (BRK))";
    color: #f9f305;
    font-size: 100px;
    text-align: center;
  }
 
  .connect2Mobile{
    display: none;
  }
  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 22px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 60px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
  
  }
  
  .storyCon2Rm {
    font-size: 18px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 28px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 200px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 20px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    text-align: center;
    margin-top: 3%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 100px;
    height: 86px;
  }
  
  .sop {
    width: 130px;
    height: 59px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 300px;
    height: 343px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 60px;
    height: 60px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 22px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 20px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 33px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 40px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 60%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .team1 {
  }
  .man {
    width: 250px;
    height: 250px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3%;
  }

  .memName {
     color: white;
    font-size: 20px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .teamH {
    font-family: "Visitor TT2 (BRK))";
    color: #f9f305;
    font-size: 100px;
    text-align: center;
  }

  .connect2Mobile{
    display: none;
  }
.icons {
  display: flex;
  flex-flow: row nowrap;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.rmH2 {
  display: none;
}

.discordF2 {
  display: none;
}

.rmboxes {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.rmP2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 25px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: rgb(0, 0, 0);
  contain: content;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest_highQ.jpg')no-repeat center center fixed;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background-color: black;
  background-size: cover;
}

.boxWrap2Art {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist_highQ.jpg')no-repeat center center fixed;
  background-size: cover;
}

.boxWrap2Team {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team_highQ.jpg')no-repeat center center fixed;
  background-size: cover;
}

.boxWrap2Rm {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG_highQ.jpg')no-repeat center center fixed;
  background-size: cover;
}

.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
  background-size: cover;
}


.boxWrapC {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  position: relative;
}

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: column nowrap;
  width: 100%;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 90%;

}

.aboutImg {
  width: 30%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 2%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s;
}

.aboutImg2:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  font-size: 60px;
  color: white;
  font-family: 'Luckiest Guy', cursive;
  background: -webkit-linear-gradient(#a7eca7, #048604);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 3%;
  letter-spacing: 5px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #ee5bf6;
  margin-bottom: 3%;
}

.tokenH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 70px;
  color: white;

  text-align: center;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}

.mintH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.storyCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.storyConRm {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #ee5bf6 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #0d158b 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm3 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #f0831f 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm4 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #89fe55 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}

.storyConRm5 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #48cf5f28;
  padding: 30px;
  border: #e9b237 10px solid;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  margin-bottom: 5%;
}


.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 23px;

}

.storyCon2Rm {
  font-size: 20px;

}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 70px;
  text-align: center;

}

.intro img {
  width: 60%;
  z-index: 100000;
}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  letter-spacing: 5px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  border: #01d100 10px solid;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  -webkit-animation: dots 1s steps(5, end) infinite;
          animation: dots 1s steps(5, end) infinite;
}

@-webkit-keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: column nowrap;
  margin-top: auto;
  margin-bottom: auto;

  align-items: center;
  width: 33.33%;
  justify-content: flex-end;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;

  color: #96ec1f;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

@-webkit-keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  border: none;
  border-radius: 10px;

  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}


.btnfos-0-2:hover:active {
  background-color: #7a1883;
}


.btnfos-0-2-2:hover:active {
  background-color: #7a1883;
}

.btnfos-0-3-1{
  margin-top: 20px;
  background-color: #00bb00;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  border: none;

  transition: transform .2s;
}

btnfos-0-3-1:active{
  background-color: #7a1883;

}

.btnfos-0-3-1:focus{
  background-color: #7a1883;
}

.btnfos-0-3-1:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.btnfos-0-3 {
  margin-top: 20px;
  background-color: #00bb00;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 28px;
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  border: none;

  transition: transform .2s;
}

.btnfos-0-3:active{
  background-color: #7a1883;

}

.btnfos-0-3:focus{
  background-color: #7a1883;
}

.btnfos-0-3:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.nftamount {
  color: white;
  font-size: 85px;

  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #48cf5e;

  font-size: 30px;
}

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
  padding-top: 20px;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #35292400;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.wallet2 {
  z-index: 1000;
  background-color: #01d100;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
  width: 200px;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  border: none;
  font-family: 'Luckiest Guy', cursive;

  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;

}

.wallet2:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.artSub {
  color: white;
  font-size: 20px;
   cursor: pointer;
  font-family: 'Luckiest Guy', cursive;
  transition: 0.5s ease;
  margin-top: 3%;

}

.artSub:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
          animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

@-webkit-keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;

  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
  margin-top: 1%;
}

@-webkit-keyframes Slide_Up {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@-webkit-keyframes Slide_Up_Tag {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@-webkit-keyframes Slide_Up_Buttons {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(250px);
            transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(192, 192, 192);
  text-align: center;
  font-size: 20px;
  padding-bottom: 10px;
}

.footer {}

.logoF {
  width: 10%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 2%;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes Slide_Left {
  0% {
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes Slide_Left {
  0% {
    -webkit-transform: translateX(250px);
            transform: translateX(250px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes Slide_Left_Right {

  0%,
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  12.5%,
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

}

@keyframes Slide_Left_Right {

  0%,
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }

  12.5%,
  75% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  25% {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }

}

@-webkit-keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@-webkit-keyframes scaleIn {
  from {
    -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    -webkit-transform: scale(2.5, 2.5);
            transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes scaleIn {
  from {
    -webkit-transform: scale(.5, .5);
            transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    -webkit-transform: scale(2.5, 2.5);
            transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@-webkit-keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

.logo {
  width: 100px;
  height: 86px;
}

.sop {
  width: 130px;
  height: 59px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.logoDiv {
  margin-top: auto;
  margin-bottom: auto;
  width: 33.33%;
}

.logoDiv1 {
  margin-top: auto;
  margin-bottom: auto;
  width: 33.33%;
  justify-content: flex-start;
}

.logo2 {
  width: 20%;
  cursor: pointer;
}

#fontSize {
  font-size: 25px;

  cursor: pointer;
  z-index: 10000;

}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/


summary {
  font-size: 17px;
  font-weight: 600;
  background: rgba(255, 255, 255, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #afe0db;
  color: white;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  -webkit-animation: sweep .5s ease-in-out;
          animation: sweep .5s ease-in-out;
}

@-webkit-keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 17px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;

}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-bottom: 10%;
  background: rgb(24, 135, 177);
  background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
  margin-top: -5%;
  z-index: 1000;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 400px;
  height: 457px;
  border-radius: 20px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.mintCon {
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-weight: 500;
  font-size: 30px;
  text-decoration: underline;
}


.rmBoxMain2 {
  margin-top: 30%;
}

.rmBoxMain {
  width: -webkit-max-content;
  width: max-content;
  justify-content: flex-start;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 200px;
  height: 189px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 24%;
  margin-top: -100%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 410vh;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 20%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  /*filter: url("#goo");*/
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
  background-size: cover;
}

.web {
  width: 100%;
  height: 100%;
  position: absolute;
}

.cont .drip {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: rgb(24, 70, 28);
  position: absolute;
  top: -100%;
  -webkit-animation: falling 5s linear infinite;
          animation: falling 5s linear infinite;
}

@-webkit-keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

@keyframes falling {
  0% {
    top: -100%;
  }

  50% {
    top: 0%;
  }

  80% {
    top: 80%;
  }

  100% {
    top: 100%;
  }
}

.cont .drip:nth-child(1) {
  border-color: black;
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 241px;
  margin-left: 60px;
}

.cont .drip:nth-child(2) {
  border-color: black;
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 175px;
  margin-left: 60px;
}

.cont .drip:nth-child(3) {
  border-color: black;
  height: 36px;
  width: 36px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 190px;
  margin-left: 60px;
}

.cont .drip:nth-child(4) {
  border-color: black;
  height: 24px;
  width: 24px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 86px;
  margin-left: 60px;
}

.cont .drip:nth-child(5) {
  border-color: black;
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 197px;
  margin-left: 60px;
}

.cont .drip:nth-child(6) {
  border-color: black;
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 244px;
  margin-left: 60px;
}

.cont .drip:nth-child(7) {
  border-color: black;
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(8) {
  border-color: black;
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 120px;
  margin-left: 60px;
}

.cont .drip:nth-child(9) {
  border-color: black;
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 29px;
  margin-left: 60px;
}

.cont .drip:nth-child(10) {
  border-color: black;
  height: 33px;
  width: 33px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 154px;
  margin-left: 60px;
}

.cont .drip:nth-child(11) {
  border-color: black;
  height: 39px;
  width: 39px;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 109px;
  margin-left: 60px;
}

.cont .drip:nth-child(12) {
  border-color: black;
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 272px;
  margin-left: 60px;
}

.cont .drip:nth-child(13) {
  border-color: black;
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(14) {
  border-color: black;
  height: 21px;
  width: 21px;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(15) {
  border-color: black;
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 95%;
  margin-left: 60px;
}

.cont .drip:nth-child(16) {
  border-color: black;
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 76%;
  margin-left: 60px;
}

.cont .drip:nth-child(17) {
  border-color: black;
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 71%;
  margin-left: 60px;
}

.cont .drip:nth-child(18) {
  border-color: black;
  height: 38px;
  width: 38px;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 46%;
  margin-left: 60px;
}

.cont .drip:nth-child(19) {
  border-color: black;
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 41%;
  margin-left: 60px;
}

.cont .drip:nth-child(20) {
  border-color: black;
  height: 19px;
  width: 19px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 33%;
  margin-left: 60px;
}


.cont .drip:nth-child(21) {
  border-color: black;
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 42%;
  margin-left: 60px;
}

.cont .drip:nth-child(22) {
  border-color: black;
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(23) {
  border-color: black;
  height: 36px;
  width: 36px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 49%;
  margin-left: 60px;
}

.cont .drip:nth-child(24) {
  border-color: black;
  height: 24px;
  width: 24px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 73%;
  margin-left: 60px;
}

.cont .drip:nth-child(25) {
  border-color: black;
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 83%;
  margin-left: 60px;
}

.cont .drip:nth-child(26) {
  border-color: black;
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 28%;
  margin-left: 60px;
}

.cont .drip:nth-child(27) {
  border-color: black;
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 14%;
  margin-left: 60px;
}

.cont .drip:nth-child(28) {
  border-color: black;
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 43%;
  margin-left: 60px;
}

.cont .drip:nth-child(29) {
  border-color: black;
  height: 27px;
  width: 27px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 68%;
  margin-left: 60px;
}

.cont .drip:nth-child(30) {
  border-color: black;
  height: 33px;
  width: 33px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 57%;
  margin-left: 60px;
}

.cont .drip:nth-child(31) {
  border-color: black;
  height: 39px;
  width: 39px;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 27%;
  margin-left: 60px;
}

.cont .drip:nth-child(32) {
  border-color: black;
  height: 23px;
  width: 23px;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 12%;
  margin-left: 60px;
}

.cont .drip:nth-child(33) {
  border-color: black;
  height: 13px;
  width: 13px;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 30%;
  margin-left: 60px;
}

.cont .drip:nth-child(34) {
  border-color: black;
  height: 21px;
  width: 21px;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 38%;
  margin-left: 60px;
}

.cont .drip:nth-child(35) {
  border-color: black;
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 55%;
  margin-left: 60px;
}

.cont .drip:nth-child(36) {
  border-color: black;
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 50%;
  margin-left: 60px;
}

.cont .drip:nth-child(37) {
  border-color: black;
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 58%;
  margin-left: 60px;
}

.cont .drip:nth-child(38) {
  border-color: black;
  height: 38px;
  width: 38px;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 67%;
  margin-left: 60px;
}

.cont .drip:nth-child(39) {
  border-color: black;
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 70%;
  margin-left: 60px;
}

.cont .drip:nth-child(40) {
  border-color: black;
  height: 19px;
  width: 19px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
  left: 90%;
  margin-left: 60px;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  font-size: 23px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  text-align: center;
  background-color: #8aa7ec;
}

.winDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1%;
}

.winDiv img {
  width: 60px;
  height: 60px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  -webkit-animation: zoominoutsinglefeatured 5s infinite;
          animation: zoominoutsinglefeatured 5s infinite;
}

@keyframes zoominoutsinglefeatured {
  0% {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
  }

  50% {
    -webkit-transform: scale(1.05, 1.05);
            transform: scale(1.05, 1.05);
  }

  100% {
    -webkit-transform: scale(1.3, 1.3);
            transform: scale(1.3, 1.3);
  }
}

.winCon {
  color: #fff18d;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #ff6b81;
}

.winCon2 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #473e61;
}

.winCon3 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #8f5c4a;
}

.winCon4 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #21b572;
}

.winCon5 {
  color: #ffcd69;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-right: 2%;
  font-size: 25px;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: #253a84;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;
}

.teamCon img {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border-radius: 10px;
  border: 3px #febf00 solid;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 20px;
  text-align: center;
  font-family: 'Mochiy Pop One', sans-serif;

}

.memberName1 {
  font-size: 33px;
  text-align: center;
  font-family: 'Mochiy Pop One', sans-serif;

}

.memberCon {
  font-size: 20px;
  text-align: center;
}

.NameAndTwitter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.NameAndTwitter img {
  width: 30px;
  height: 30px;
}

.twitterTeam {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.twitterTeamDiv {
  background-color: #ffffff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 2%;
  padding: 10px;
  border-radius: 100%;
  cursor: pointer;
  transition: 0.5s ease;

}

.twitterTeamDiv:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-filter: none;
          filter: none;
}

.art {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  width: 50%;
  border: 2px solid greenyellow;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: 0.5s ease;
}

.art:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-filter: none;
          filter: none;
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
}

.lightrope li {
  position: relative;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: #00f7a5;
  box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  -webkit-animation-name: flash-1;
  animation-name: flash-1;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n+1) {
  background: aqua;
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
  -webkit-animation-name: flash-2;
  animation-name: flash-2;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n+2) {
  background: #f70094;
  box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  -webkit-animation-name: flash-3;
  animation-name: flash-3;
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  -webkit-animation-duration: 1.8s;
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n+1) {
  -webkit-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.3333333333px;
  border-radius: 3px;
  top: -4.6666666667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.6666666667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@-webkit-keyframes flash-1 {

  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-1 {

  0%,
  100% {
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
  }

  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@-webkit-keyframes flash-2 {

  0%,
  100% {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px aqua;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-2 {

  0%,
  100% {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px aqua;
  }

  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@-webkit-keyframes flash-3 {

  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

@keyframes flash-3 {

  0%,
  100% {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
  }

  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

.footerIcons {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1%;
}

.footerIcons img {
  width: 45px;
  height: 45px;
  margin-left: 10px;
  margin-right: 10px;
  transition: 0.5s ease;
  cursor: pointer;
}

.footerIcons img:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-filter: none;
          filter: none;
}

/* Start https://www.cursors-4u.com */
* {
  cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
}

/* End https://www.cursors-4u.com */

/* Basic Reset */

/* Background */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -10%;
}

/* Footprint */
.footprint {
  position: absolute;
  top: 20%;
  left: 0;
  -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
          animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
  text-align: center;
}

/* Image Size */
.foot {
  max-width: 20%;
}

/* Right Footprint */
.right {
  margin: 5rem;
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}

/* Walking Animation */
@-webkit-keyframes walk {
  to {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
}
@keyframes walk {
  to {
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
}

/* Opacity During Walking */
@-webkit-keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}
@keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .team1 {
  }
  .man {
    width: 370px;
    height: 370px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3%;
  }

  .memName {
     color: white;
    font-size: 30px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .teamH {
    font-family: "Visitor TT2 (BRK))";
    color: #f9f305;
    font-size: 100px;
    text-align: center;
  }
  .connect2Mobile{
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 40px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 80px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 28px;
  
  }
  
  .storyCon2Rm {
    font-size: 25px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 24px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 25px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 16px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
     border: none;
    border-radius: 10px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 80px;
    height: 80px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 35px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 85px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 30px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;  
  }  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    width: 250px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 2%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 20px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 7%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 120px;
    height: 104px;
  }
  
  .sop {
    width: 150px;
    height: 68px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 500px;
    height: 571px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 28px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 28px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 28px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 28px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 28px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 35px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 45px;
    height: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 45px;
    height: 45px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .team1 {
  }
  .man {
    width: 495px;
    height: 495px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3%;
  }

  .memName {
     color: white;
    font-size: 38px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .teamH {
    font-family: "Visitor TT2 (BRK))";
    color: #f9f305;
    font-size: 100px;
    text-align: center;
  }
  .connect2Mobile{
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 45px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 100px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 40px;
  
  }
  
  .storyCon2Rm {
    font-size: 28px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 28px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 20px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
     border: none;
    border-radius: 10px;
    width: 90px;
    height: 90px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 110px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 35px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;  
  }  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    width: 250px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 2%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 23px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 7%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 120px;
    height: 104px;
  }
  
  .sop {
    width: 150px;
    height: 68px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 600px;
    height: 686px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 28%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 35px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}

@media screen and (min-width: 3840px) {
  .team1 {
  }
  .man {
    width: 700px;
    height: 700px;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }

  .manDiv {
     width: -webkit-fit-content;
     width: -moz-fit-content;
     width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .tAndName {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 3%;
  }

  .memName {
     color: white;
    font-size: 55px;
    text-align: center;
    padding-right: 2%;

  }

  .memNamePosition {
    color: white;
    font-size: 17px;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .tPic  {z-index: 10000;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
     margin-left: auto;
    margin-right: auto;
    margin-bottom: 5%;
    width: 90%;
    justify-content: space-between;
  }

  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .teamH {
    font-family: "Visitor TT2 (BRK))";
    color: #f9f305;
    font-size: 100px;
    text-align: center;
  }
  .connect2Mobile{
    display: none;
  }

  .icons {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .rmH2 {
    display: none;
  }
  
  .discordF2 {
    display: none;
  }
  
  .rmboxes {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  
  .rmP2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 45px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
  
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: rgb(0, 0, 0);
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/forest_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background-color: black;
    background-size: cover;
  }
  
  .boxWrap2Art {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/artist_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/team_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Rm {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/rmBG_highQ.jpg')no-repeat center center fixed;
    background-size: cover;
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9611441988904937) 1%, rgba(0, 0, 0, 0.2860741708792892) 61%, rgba(0, 0, 0, 0.9079229103750875) 96%), url('https://sopassets.pages.dev/sop/mintBg.jfif')no-repeat center center fixed;
    background-size: cover;
  }
  
  
  .boxWrapC {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    position: relative;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
    width: 100%;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  
  }
  
  .aboutImg {
    width: 30%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
  }
  
  .aboutImg2:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 100px;
    color: white;
    font-family: 'Luckiest Guy', cursive;
    background: -webkit-linear-gradient(#a7eca7, #048604);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 3%;
    letter-spacing: 5px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ee5bf6;
    margin-bottom: 3%;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    color: white;
  
    text-align: center;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  .mintH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .storyCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConRm {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #ee5bf6 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #0d158b 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm3 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #f0831f 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm4 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #89fe55 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  .storyConRm5 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    background-color: #48cf5f28;
    padding: 30px;
    border: #e9b237 10px solid;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    margin-bottom: 5%;
  }
  
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 40px;
  
  }
  
  .storyCon2Rm {
    font-size: 28px;
  
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 70px;
    text-align: center;
  
  }
  
  .intro img {
    width: 60%;
    z-index: 100000;
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    letter-spacing: 5px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
    border: #01d100 10px solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 28px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 30px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 20px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }
  
  @-webkit-keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  
    align-items: center;
    width: 33.33%;
    justify-content: flex-end;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
  
    color: #96ec1f;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }
  
  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
     border: none;
    border-radius: 10px;
    width: 90px;
    height: 90px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 10px;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #7a1883;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1{
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  btnfos-0-3-1:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3-1:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3-1:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .btnfos-0-3 {
    margin-top: 20px;
    background-color: #00bb00;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
  
    transition: transform .2s;
  }
  
  .btnfos-0-3:active{
    background-color: #7a1883;
  
  }
  
  .btnfos-0-3:focus{
    background-color: #7a1883;
  }
  
  .btnfos-0-3:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .nftamount {
    color: white;
    font-size: 110px;
  
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #48cf5e;
  
    font-size: 35px;
  }
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #35292400;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;  
  }  
  
  .wallet2 {
    z-index: 1000;
    background-color: #01d100;
    color: #ffffff;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    border-radius: 50px;
    width: 250px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
    border: none;
    font-family: 'Luckiest Guy', cursive;
  
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
  
  }
  
  .wallet2:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .artSub {
    color: white;
    font-size: 25px;
    cursor: pointer;
    font-family: 'Luckiest Guy', cursive;
    transition: 0.5s ease;
    margin-top: 2%;
  }
  
  .artSub:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    -webkit-animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
  
  @-webkit-keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 90px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
  
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
    margin-top: 1%;
  }
  
  @-webkit-keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @-webkit-keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(250px);
              transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(192, 192, 192);
    text-align: center;
    font-size: 23px;
    padding-bottom: 10px;
  }
  
  .footer {}
  
  .logoF {
    width: 7%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
  }
  
  @-webkit-keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      -webkit-transform: translateX(250px);
              transform: translateX(250px);
    }
  
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  
  @-webkit-keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
    }
  
    12.5%,
    75% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  
    25% {
      -webkit-transform: translateX(20px);
              transform: translateX(20px);
    }
  
  }
  
  @-webkit-keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @-webkit-keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes scaleIn {
    from {
      -webkit-transform: scale(.5, .5);
              transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      -webkit-transform: scale(2.5, 2.5);
              transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 120px;
    height: 104px;
  }
  
  .sop {
    width: 150px;
    height: 68px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .logoDiv {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
  }
  
  .logoDiv1 {
    margin-top: auto;
    margin-bottom: auto;
    width: 33.33%;
    justify-content: flex-start;
  }
  
  .logo2 {
    width: 20%;
    cursor: pointer;
  }
  
  #fontSize {
    font-size: 25px;
  
    cursor: pointer;
    z-index: 10000;
  
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  summary {
    font-size: 17px;
    font-weight: 600;
    background: rgba(255, 255, 255, 0);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: 1px solid #afe0db;
    color: white;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 50%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
  
    color: rgba(255, 255, 255, 0.829);
    padding-bottom: 20px;
    text-align: left;
    font-size: 17px;
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-bottom: 10%;
    background: rgb(24, 135, 177);
    background: linear-gradient(0deg, rgba(24, 135, 177, 1) 0%, rgba(102, 198, 183, 1) 63%, rgba(102, 196, 182, 1) 83%);
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
    margin-top: -5%;
    z-index: 1000;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 600px;
    height: 686px;
    border-radius: 20px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .mintCon {
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-weight: 500;
    font-size: 30px;
    text-decoration: underline;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
  }
  
  .rmBoxMain {
    width: -webkit-max-content;
    width: max-content;
    justify-content: flex-start;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 200px;
    height: 189px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 24%;
    margin-top: -100%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 410vh;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 20%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    /*filter: url("#goo");*/
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9583430784423145) 1%, rgba(0, 0, 0, 0.4233290728400735) 61%, rgba(0, 0, 0, 0.4429369159773284) 96%), url('https://sopassets.pages.dev/sop/bg.jfif');
    background-size: cover;
  }
  
  .web {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .cont .drip {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: rgb(24, 70, 28);
    position: absolute;
    top: -100%;
    -webkit-animation: falling 5s linear infinite;
            animation: falling 5s linear infinite;
  }
  
  @-webkit-keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  @keyframes falling {
    0% {
      top: -100%;
    }
  
    50% {
      top: 0%;
    }
  
    80% {
      top: 80%;
    }
  
    100% {
      top: 100%;
    }
  }
  
  .cont .drip:nth-child(1) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 241px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(2) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 175px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(3) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 190px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(4) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 86px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(5) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 197px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(6) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 244px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(7) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(8) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 120px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(9) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 29px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(10) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 154px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(11) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 109px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(12) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 272px;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(13) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(14) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(15) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 95%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(16) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 76%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(17) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 71%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(18) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 46%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(19) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 41%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(20) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 33%;
    margin-left: 60px;
  }
  
  
  .cont .drip:nth-child(21) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.2s;
            animation-delay: -0.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 42%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(22) {
    border-color: black;
    height: 9px;
    width: 9px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(23) {
    border-color: black;
    height: 36px;
    width: 36px;
    -webkit-animation-delay: -0.6s;
            animation-delay: -0.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 49%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(24) {
    border-color: black;
    height: 24px;
    width: 24px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 73%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(25) {
    border-color: black;
    height: 5px;
    width: 5px;
    -webkit-animation-delay: -1s;
            animation-delay: -1s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 83%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(26) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 28%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(27) {
    border-color: black;
    height: 11px;
    width: 11px;
    -webkit-animation-delay: -1.4s;
            animation-delay: -1.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 14%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(28) {
    border-color: black;
    height: 10px;
    width: 10px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 43%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(29) {
    border-color: black;
    height: 27px;
    width: 27px;
    -webkit-animation-delay: -1.8s;
            animation-delay: -1.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 68%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(30) {
    border-color: black;
    height: 33px;
    width: 33px;
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 57%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(31) {
    border-color: black;
    height: 39px;
    width: 39px;
    -webkit-animation-delay: -2.2s;
            animation-delay: -2.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 27%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(32) {
    border-color: black;
    height: 23px;
    width: 23px;
    -webkit-animation-delay: -2.4s;
            animation-delay: -2.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 12%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(33) {
    border-color: black;
    height: 13px;
    width: 13px;
    -webkit-animation-delay: -2.6s;
            animation-delay: -2.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 30%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(34) {
    border-color: black;
    height: 21px;
    width: 21px;
    -webkit-animation-delay: -2.8s;
            animation-delay: -2.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 38%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(35) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 55%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(36) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.2s;
            animation-delay: -3.2s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 50%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(37) {
    border-color: black;
    height: 12px;
    width: 12px;
    -webkit-animation-delay: -3.4s;
            animation-delay: -3.4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 58%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(38) {
    border-color: black;
    height: 38px;
    width: 38px;
    -webkit-animation-delay: -3.6s;
            animation-delay: -3.6s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 67%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(39) {
    border-color: black;
    height: 1px;
    width: 1px;
    -webkit-animation-delay: -3.8s;
            animation-delay: -3.8s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 70%;
    margin-left: 60px;
  }
  
  .cont .drip:nth-child(40) {
    border-color: black;
    height: 19px;
    width: 19px;
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    background: linear-gradient(180deg, rgba(150, 227, 97, 1) 11%, rgba(59, 167, 77, 1) 65%, rgba(29, 88, 39, 1) 94%);
    left: 90%;
    margin-left: 60px;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    font-size: 23px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    text-align: center;
    background-color: #8aa7ec;
  }
  
  .winDiv {
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
  }
  
  .winDiv img {
    width: 80px;
    height: 80px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    -webkit-animation: zoominoutsinglefeatured 5s infinite;
            animation: zoominoutsinglefeatured 5s infinite;
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  
    50% {
      -webkit-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
    }
  
    100% {
      -webkit-transform: scale(1.3, 1.3);
              transform: scale(1.3, 1.3);
    }
  }
  
  .winCon {
    color: #fff18d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #ff6b81;
  }
  
  .winCon2 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #473e61;
  }
  
  .winCon3 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #8f5c4a;
  }
  
  .winCon4 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #21b572;
  }
  
  .winCon5 {
    color: #ffcd69;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 2%;
    font-size: 33px;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: #253a84;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  }
  
  .teamCon img {
    width: 28%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border-radius: 10px;
    border: 3px #febf00 solid;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberName1 {
    font-size: 35px;
    text-align: center;
    font-family: 'Mochiy Pop One', sans-serif;
  
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  .NameAndTwitter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .NameAndTwitter img {
    width: 30px;
    height: 30px;
  }
  
  .twitterTeam {
    width: 50px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .twitterTeamDiv {
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 2%;
    padding: 10px;
    border-radius: 100%;
    cursor: pointer;
    transition: 0.5s ease;
  
  }
  
  .twitterTeamDiv:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-filter: none;
            filter: none;
  }
  
  .art {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 50%;
    border: 2px solid greenyellow;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    transition: 0.5s ease;
  }
  
  .art:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: none;
            filter: none;
  }
  
  .lightrope {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    pointer-events: none;
    width: 100%;
  }
  
  .lightrope li {
    position: relative;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: 12px;
    height: 28px;
    border-radius: 50%;
    margin: 20px;
    display: inline-block;
    background: #00f7a5;
    box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    -webkit-animation-name: flash-1;
    animation-name: flash-1;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  
  .lightrope li:nth-child(2n+1) {
    background: aqua;
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
    -webkit-animation-name: flash-2;
    animation-name: flash-2;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
  }
  
  .lightrope li:nth-child(4n+2) {
    background: #f70094;
    box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    -webkit-animation-name: flash-3;
    animation-name: flash-3;
    -webkit-animation-duration: 1.1s;
    animation-duration: 1.1s;
  }
  
  .lightrope li:nth-child(odd) {
    -webkit-animation-duration: 1.8s;
    animation-duration: 1.8s;
  }
  
  .lightrope li:nth-child(3n+1) {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
  }
  
  .lightrope li:before {
    content: "";
    position: absolute;
    background: #222;
    width: 10px;
    height: 9.3333333333px;
    border-radius: 3px;
    top: -4.6666666667px;
    left: 1px;
  }
  
  .lightrope li:after {
    content: "";
    top: -14px;
    left: 9px;
    position: absolute;
    width: 52px;
    height: 18.6666666667px;
    border-bottom: solid #222 2px;
    border-radius: 50%;
  }
  
  .lightrope li:last-child:after {
    content: none;
  }
  
  .lightrope li:first-child {
    margin-left: -40px;
  }
  
  @-webkit-keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @keyframes flash-1 {
  
    0%,
    100% {
      background: #00f7a5;
      box-shadow: 0px 4.6666666667px 24px 3px #00f7a5;
    }
  
    50% {
      background: rgba(0, 247, 165, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
    }
  }
  
  @-webkit-keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @keyframes flash-2 {
  
    0%,
    100% {
      background: aqua;
      box-shadow: 0px 4.6666666667px 24px 3px aqua;
    }
  
    50% {
      background: rgba(0, 255, 255, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
    }
  }
  
  @-webkit-keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  @keyframes flash-3 {
  
    0%,
    100% {
      background: #f70094;
      box-shadow: 0px 4.6666666667px 24px 3px #f70094;
    }
  
    50% {
      background: rgba(247, 0, 148, 0.4);
      box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
    }
  }
  
  .footerIcons {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
  }
  
  .footerIcons img {
    width: 55px;
    height: 55px;
    margin-left: 10px;
    margin-right: 10px;
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .footerIcons img:hover {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: none;
            filter: none;
  }
  
  /* Start https://www.cursors-4u.com */
  * {
    cursor: url(https://cur.cursors-4u.net/toons/too-1/too38.ani), url(https://cur.cursors-4u.net/toons/too-1/too38.png), auto !important;
  }
  
  /* End https://www.cursors-4u.com */
  
  /* Basic Reset */
  
  /* Background */
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    -webkit-animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
            animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .right {
    margin: 5rem;
    -webkit-animation-delay: 250ms;
            animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @-webkit-keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  @keyframes walk {
    to {
      -webkit-transform: translateX(100vw);
              transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @-webkit-keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
}


@media screen and (max-width: 499px) {

  .carouselIMG {
    width: 220px;
    height: 251px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .carouselIMG {
    width: 250px;
    height: 286px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .carouselIMG {
    width: 250px;
    height: 286px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .carouselIMG {
    width: 300px;
    height: 343px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .carouselIMG {
    width: 300px;
    height: 343px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 5%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}

@media screen and (min-width: 1920px) {

  .carouselIMG {
    width: 330px;
    height: 377px;
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .boxWrapC {
    width: 100%;
  }

  @-webkit-keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(96, 37, 163);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(104, 27, 128, 0.233);
    }
  }

  @-webkit-keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @keyframes scroll {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-250px * 8));
              transform: translateX(calc(-250px * 8));
    }
  }

  @-webkit-keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @keyframes scroll2 {
    0% {
      -webkit-transform: translateX(-1500px);
              transform: translateX(-1500px);
    }

    100% {
      -webkit-transform: translateX(calc(0 * 16));
              transform: translateX(calc(0 * 16));
    }
  }

  @-webkit-keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  @keyframes scroll3 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }

    100% {
      -webkit-transform: translateX(calc(-90px * 7));
              transform: translateX(calc(-90px * 7));
    }
  }

  .slider {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-bottom: 10%;
  }

  .slider2 {
    height: 400px;
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -5%;
  }

  .slider::before,
  .slider::after {
    content: "";
    height: 500px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  .slider::after {
    right: 0;
    top: 0;
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
  }

  .slider::before {
    left: 0;
    top: 0;
  }

  .slider .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track2 {
    -webkit-animation: scroll2 40s linear infinite;
            animation: scroll2 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }

  .slider2 .slide-track3 {
    -webkit-animation: scroll3 40s linear infinite;
            animation: scroll3 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }


  .slider .slide {
    height: 250px;
    width: 435px;
  }

  .slider2 .slide {
    height: 250px;
    width: 435px;
  }

  .welcome {
    font-size: 45px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    padding: 50px;
    color: white;
    background-color: rgb(22, 22, 22);
  }
}
@media screen and (max-width: 499px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 100%;
    max-width: 1500px;
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    -webkit-animation-name: carouselAnim; 
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 120px;
    height: 137px;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 500px) and (max-width: 767px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 90%;
    max-width: 960px;
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    -webkit-animation-name: carouselAnim; 
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 768px) and (max-width: 991px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 80%;
    max-width: 960px;
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 992px) and (max-width: 1199px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 80%;
    max-width: 960px;
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
} 

@media screen and (min-width: 1200px) and (max-width: 1919px) {



  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }



  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 100%;
    max-width: 960px;
    -webkit-perspective: 300px;
            perspective: 300px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 0.5%;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 100%;
    border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 1600px;
    -webkit-perspective: 400px;
            perspective: 400px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 500px;
    height: 571px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 2000px;
    -webkit-perspective: 400px;
            perspective: 400px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 600px;
    height: 686px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}

@media screen and (min-width: 3840px) {

 
  @-webkit-keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }

 
  @keyframes carouselAnim{
    0%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    27%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
    33.33333%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    60%{
      -webkit-transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
              transform: translateX(0) translateY(0) rotatey(10deg) scale(0.8,1);
      -webkit-transform-origin: left center;
              transform-origin: left center;
      z-index: 5;
    }
    66.666666%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    93%{
      -webkit-transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
              transform: translateX(200%) translateY(0) rotatey(-10deg) scale(0.8,1);
      -webkit-transform-origin: right center;
              transform-origin: right center;
      z-index: 5;
    }
    100%{
      -webkit-transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
              transform: translateX(100%) rotatey(0) scale(1.2, 1.2);
      -webkit-transform-origin: center center;
              transform-origin: center center;
      z-index: 10;
    }
  }
  
  .carousel__container{
    width: 200%;
    max-width: 2000px;
    -webkit-perspective: 400px;
            perspective: 400px;
    -webkit-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
    min-height: 35em;
    margin: 3em auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
     display: block;
  }
  
  .carousel__item{
    position: absolute;
    width: 33.33%; 
    padding-bottom: 19%;
    -webkit-animation-name: carouselAnim;
            animation-name: carouselAnim;
    /* transform: translateX(0) rotatey(50deg); */
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-animation-duration: 12s;
            animation-duration: 12s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    
  }
  
  .carousel__item:first-child{
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
  }
  .carousel__item:last-child{
    -webkit-animation-delay: -8s;
            animation-delay: -8s;
  }
  
  .carousel__image{
    position: absolute;
    width: 600px;
    height: 686px;
     border-radius: 2px;
    border: 2px solid #02ac00;
  }
}
.firefly {
  position: -webkit-sticky;
  position: sticky;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  -webkit-animation: ease 200s alternate infinite;
          animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before, .firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform-origin: -10vw;
          transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  -webkit-animation: drift ease alternate infinite;
          animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw #FFF01F;
  -webkit-animation: drift ease alternate infinite, flash ease infinite;
          animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  -webkit-animation-name: move1;
          animation-name: move1;
}
.firefly:nth-child(1)::before {
  -webkit-animation-duration: 14s;
          animation-duration: 14s;
}
.firefly:nth-child(1)::after {
  -webkit-animation-duration: 14s, 5348ms;
          animation-duration: 14s, 5348ms;
  -webkit-animation-delay: 0ms, 3421ms;
          animation-delay: 0ms, 3421ms;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: translateX(-41vw) translateY(44vh) scale(0.62);
            transform: translateX(-41vw) translateY(44vh) scale(0.62);
  }
  4% {
    -webkit-transform: translateX(-4vw) translateY(50vh) scale(0.77);
            transform: translateX(-4vw) translateY(50vh) scale(0.77);
  }
  8% {
    -webkit-transform: translateX(43vw) translateY(-35vh) scale(0.85);
            transform: translateX(43vw) translateY(-35vh) scale(0.85);
  }
  12% {
    -webkit-transform: translateX(6vw) translateY(-16vh) scale(0.32);
            transform: translateX(6vw) translateY(-16vh) scale(0.32);
  }
  16% {
    -webkit-transform: translateX(40vw) translateY(-28vh) scale(0.61);
            transform: translateX(40vw) translateY(-28vh) scale(0.61);
  }
  20% {
    -webkit-transform: translateX(16vw) translateY(-33vh) scale(0.62);
            transform: translateX(16vw) translateY(-33vh) scale(0.62);
  }
  24% {
    -webkit-transform: translateX(-13vw) translateY(12vh) scale(0.99);
            transform: translateX(-13vw) translateY(12vh) scale(0.99);
  }
  28% {
    -webkit-transform: translateX(-6vw) translateY(15vh) scale(0.52);
            transform: translateX(-6vw) translateY(15vh) scale(0.52);
  }
  32% {
    -webkit-transform: translateX(39vw) translateY(40vh) scale(0.42);
            transform: translateX(39vw) translateY(40vh) scale(0.42);
  }
  36% {
    -webkit-transform: translateX(-19vw) translateY(-31vh) scale(0.72);
            transform: translateX(-19vw) translateY(-31vh) scale(0.72);
  }
  40% {
    -webkit-transform: translateX(47vw) translateY(18vh) scale(0.74);
            transform: translateX(47vw) translateY(18vh) scale(0.74);
  }
  44% {
    -webkit-transform: translateX(-24vw) translateY(1vh) scale(0.8);
            transform: translateX(-24vw) translateY(1vh) scale(0.8);
  }
  48% {
    -webkit-transform: translateX(36vw) translateY(-6vh) scale(0.65);
            transform: translateX(36vw) translateY(-6vh) scale(0.65);
  }
  52% {
    -webkit-transform: translateX(-6vw) translateY(11vh) scale(0.28);
            transform: translateX(-6vw) translateY(11vh) scale(0.28);
  }
  56% {
    -webkit-transform: translateX(34vw) translateY(35vh) scale(0.79);
            transform: translateX(34vw) translateY(35vh) scale(0.79);
  }
  60% {
    -webkit-transform: translateX(-33vw) translateY(-35vh) scale(0.48);
            transform: translateX(-33vw) translateY(-35vh) scale(0.48);
  }
  64% {
    -webkit-transform: translateX(33vw) translateY(-1vh) scale(0.47);
            transform: translateX(33vw) translateY(-1vh) scale(0.47);
  }
  68% {
    -webkit-transform: translateX(-45vw) translateY(-41vh) scale(0.54);
            transform: translateX(-45vw) translateY(-41vh) scale(0.54);
  }
  72% {
    -webkit-transform: translateX(-20vw) translateY(40vh) scale(0.92);
            transform: translateX(-20vw) translateY(40vh) scale(0.92);
  }
  76% {
    -webkit-transform: translateX(-12vw) translateY(-16vh) scale(0.64);
            transform: translateX(-12vw) translateY(-16vh) scale(0.64);
  }
  80% {
    -webkit-transform: translateX(-23vw) translateY(-18vh) scale(0.92);
            transform: translateX(-23vw) translateY(-18vh) scale(0.92);
  }
  84% {
    -webkit-transform: translateX(-37vw) translateY(7vh) scale(0.98);
            transform: translateX(-37vw) translateY(7vh) scale(0.98);
  }
  88% {
    -webkit-transform: translateX(-31vw) translateY(-35vh) scale(0.92);
            transform: translateX(-31vw) translateY(-35vh) scale(0.92);
  }
  92% {
    -webkit-transform: translateX(1vw) translateY(-43vh) scale(0.29);
            transform: translateX(1vw) translateY(-43vh) scale(0.29);
  }
  96% {
    -webkit-transform: translateX(-41vw) translateY(-20vh) scale(0.44);
            transform: translateX(-41vw) translateY(-20vh) scale(0.44);
  }
  100% {
    -webkit-transform: translateX(-4vw) translateY(-33vh) scale(0.38);
            transform: translateX(-4vw) translateY(-33vh) scale(0.38);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: translateX(-41vw) translateY(44vh) scale(0.62);
            transform: translateX(-41vw) translateY(44vh) scale(0.62);
  }
  4% {
    -webkit-transform: translateX(-4vw) translateY(50vh) scale(0.77);
            transform: translateX(-4vw) translateY(50vh) scale(0.77);
  }
  8% {
    -webkit-transform: translateX(43vw) translateY(-35vh) scale(0.85);
            transform: translateX(43vw) translateY(-35vh) scale(0.85);
  }
  12% {
    -webkit-transform: translateX(6vw) translateY(-16vh) scale(0.32);
            transform: translateX(6vw) translateY(-16vh) scale(0.32);
  }
  16% {
    -webkit-transform: translateX(40vw) translateY(-28vh) scale(0.61);
            transform: translateX(40vw) translateY(-28vh) scale(0.61);
  }
  20% {
    -webkit-transform: translateX(16vw) translateY(-33vh) scale(0.62);
            transform: translateX(16vw) translateY(-33vh) scale(0.62);
  }
  24% {
    -webkit-transform: translateX(-13vw) translateY(12vh) scale(0.99);
            transform: translateX(-13vw) translateY(12vh) scale(0.99);
  }
  28% {
    -webkit-transform: translateX(-6vw) translateY(15vh) scale(0.52);
            transform: translateX(-6vw) translateY(15vh) scale(0.52);
  }
  32% {
    -webkit-transform: translateX(39vw) translateY(40vh) scale(0.42);
            transform: translateX(39vw) translateY(40vh) scale(0.42);
  }
  36% {
    -webkit-transform: translateX(-19vw) translateY(-31vh) scale(0.72);
            transform: translateX(-19vw) translateY(-31vh) scale(0.72);
  }
  40% {
    -webkit-transform: translateX(47vw) translateY(18vh) scale(0.74);
            transform: translateX(47vw) translateY(18vh) scale(0.74);
  }
  44% {
    -webkit-transform: translateX(-24vw) translateY(1vh) scale(0.8);
            transform: translateX(-24vw) translateY(1vh) scale(0.8);
  }
  48% {
    -webkit-transform: translateX(36vw) translateY(-6vh) scale(0.65);
            transform: translateX(36vw) translateY(-6vh) scale(0.65);
  }
  52% {
    -webkit-transform: translateX(-6vw) translateY(11vh) scale(0.28);
            transform: translateX(-6vw) translateY(11vh) scale(0.28);
  }
  56% {
    -webkit-transform: translateX(34vw) translateY(35vh) scale(0.79);
            transform: translateX(34vw) translateY(35vh) scale(0.79);
  }
  60% {
    -webkit-transform: translateX(-33vw) translateY(-35vh) scale(0.48);
            transform: translateX(-33vw) translateY(-35vh) scale(0.48);
  }
  64% {
    -webkit-transform: translateX(33vw) translateY(-1vh) scale(0.47);
            transform: translateX(33vw) translateY(-1vh) scale(0.47);
  }
  68% {
    -webkit-transform: translateX(-45vw) translateY(-41vh) scale(0.54);
            transform: translateX(-45vw) translateY(-41vh) scale(0.54);
  }
  72% {
    -webkit-transform: translateX(-20vw) translateY(40vh) scale(0.92);
            transform: translateX(-20vw) translateY(40vh) scale(0.92);
  }
  76% {
    -webkit-transform: translateX(-12vw) translateY(-16vh) scale(0.64);
            transform: translateX(-12vw) translateY(-16vh) scale(0.64);
  }
  80% {
    -webkit-transform: translateX(-23vw) translateY(-18vh) scale(0.92);
            transform: translateX(-23vw) translateY(-18vh) scale(0.92);
  }
  84% {
    -webkit-transform: translateX(-37vw) translateY(7vh) scale(0.98);
            transform: translateX(-37vw) translateY(7vh) scale(0.98);
  }
  88% {
    -webkit-transform: translateX(-31vw) translateY(-35vh) scale(0.92);
            transform: translateX(-31vw) translateY(-35vh) scale(0.92);
  }
  92% {
    -webkit-transform: translateX(1vw) translateY(-43vh) scale(0.29);
            transform: translateX(1vw) translateY(-43vh) scale(0.29);
  }
  96% {
    -webkit-transform: translateX(-41vw) translateY(-20vh) scale(0.44);
            transform: translateX(-41vw) translateY(-20vh) scale(0.44);
  }
  100% {
    -webkit-transform: translateX(-4vw) translateY(-33vh) scale(0.38);
            transform: translateX(-4vw) translateY(-33vh) scale(0.38);
  }
}
.firefly:nth-child(2) {
  -webkit-animation-name: move2;
          animation-name: move2;
}
.firefly:nth-child(2)::before {
  -webkit-animation-duration: 11s;
          animation-duration: 11s;
}
.firefly:nth-child(2)::after {
  -webkit-animation-duration: 11s, 6289ms;
          animation-duration: 11s, 6289ms;
  -webkit-animation-delay: 0ms, 6547ms;
          animation-delay: 0ms, 6547ms;
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: translateX(-17vw) translateY(40vh) scale(0.32);
            transform: translateX(-17vw) translateY(40vh) scale(0.32);
  }
  3.5714285714% {
    -webkit-transform: translateX(9vw) translateY(34vh) scale(0.67);
            transform: translateX(9vw) translateY(34vh) scale(0.67);
  }
  7.1428571429% {
    -webkit-transform: translateX(-21vw) translateY(-5vh) scale(0.64);
            transform: translateX(-21vw) translateY(-5vh) scale(0.64);
  }
  10.7142857143% {
    -webkit-transform: translateX(47vw) translateY(6vh) scale(0.76);
            transform: translateX(47vw) translateY(6vh) scale(0.76);
  }
  14.2857142857% {
    -webkit-transform: translateX(-16vw) translateY(-25vh) scale(0.93);
            transform: translateX(-16vw) translateY(-25vh) scale(0.93);
  }
  17.8571428571% {
    -webkit-transform: translateX(34vw) translateY(41vh) scale(0.36);
            transform: translateX(34vw) translateY(41vh) scale(0.36);
  }
  21.4285714286% {
    -webkit-transform: translateX(-34vw) translateY(38vh) scale(0.46);
            transform: translateX(-34vw) translateY(38vh) scale(0.46);
  }
  25% {
    -webkit-transform: translateX(-23vw) translateY(-42vh) scale(0.58);
            transform: translateX(-23vw) translateY(-42vh) scale(0.58);
  }
  28.5714285714% {
    -webkit-transform: translateX(6vw) translateY(-31vh) scale(0.93);
            transform: translateX(6vw) translateY(-31vh) scale(0.93);
  }
  32.1428571429% {
    -webkit-transform: translateX(-37vw) translateY(31vh) scale(0.47);
            transform: translateX(-37vw) translateY(31vh) scale(0.47);
  }
  35.7142857143% {
    -webkit-transform: translateX(49vw) translateY(-37vh) scale(0.58);
            transform: translateX(49vw) translateY(-37vh) scale(0.58);
  }
  39.2857142857% {
    -webkit-transform: translateX(13vw) translateY(-42vh) scale(0.86);
            transform: translateX(13vw) translateY(-42vh) scale(0.86);
  }
  42.8571428571% {
    -webkit-transform: translateX(34vw) translateY(41vh) scale(0.48);
            transform: translateX(34vw) translateY(41vh) scale(0.48);
  }
  46.4285714286% {
    -webkit-transform: translateX(-13vw) translateY(-13vh) scale(0.85);
            transform: translateX(-13vw) translateY(-13vh) scale(0.85);
  }
  50% {
    -webkit-transform: translateX(7vw) translateY(49vh) scale(0.39);
            transform: translateX(7vw) translateY(49vh) scale(0.39);
  }
  53.5714285714% {
    -webkit-transform: translateX(44vw) translateY(-15vh) scale(0.9);
            transform: translateX(44vw) translateY(-15vh) scale(0.9);
  }
  57.1428571429% {
    -webkit-transform: translateX(-2vw) translateY(-20vh) scale(0.27);
            transform: translateX(-2vw) translateY(-20vh) scale(0.27);
  }
  60.7142857143% {
    -webkit-transform: translateX(-3vw) translateY(34vh) scale(0.51);
            transform: translateX(-3vw) translateY(34vh) scale(0.51);
  }
  64.2857142857% {
    -webkit-transform: translateX(-40vw) translateY(-5vh) scale(0.39);
            transform: translateX(-40vw) translateY(-5vh) scale(0.39);
  }
  67.8571428571% {
    -webkit-transform: translateX(1vw) translateY(39vh) scale(0.53);
            transform: translateX(1vw) translateY(39vh) scale(0.53);
  }
  71.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(-12vh) scale(0.45);
            transform: translateX(-42vw) translateY(-12vh) scale(0.45);
  }
  75% {
    -webkit-transform: translateX(-17vw) translateY(-36vh) scale(0.63);
            transform: translateX(-17vw) translateY(-36vh) scale(0.63);
  }
  78.5714285714% {
    -webkit-transform: translateX(27vw) translateY(36vh) scale(0.92);
            transform: translateX(27vw) translateY(36vh) scale(0.92);
  }
  82.1428571429% {
    -webkit-transform: translateX(18vw) translateY(-5vh) scale(0.8);
            transform: translateX(18vw) translateY(-5vh) scale(0.8);
  }
  85.7142857143% {
    -webkit-transform: translateX(40vw) translateY(13vh) scale(0.92);
            transform: translateX(40vw) translateY(13vh) scale(0.92);
  }
  89.2857142857% {
    -webkit-transform: translateX(3vw) translateY(-4vh) scale(0.33);
            transform: translateX(3vw) translateY(-4vh) scale(0.33);
  }
  92.8571428571% {
    -webkit-transform: translateX(-31vw) translateY(43vh) scale(0.71);
            transform: translateX(-31vw) translateY(43vh) scale(0.71);
  }
  96.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(-18vh) scale(0.64);
            transform: translateX(-42vw) translateY(-18vh) scale(0.64);
  }
  100% {
    -webkit-transform: translateX(24vw) translateY(14vh) scale(0.67);
            transform: translateX(24vw) translateY(14vh) scale(0.67);
  }
}

@keyframes move2 {
  0% {
    -webkit-transform: translateX(-17vw) translateY(40vh) scale(0.32);
            transform: translateX(-17vw) translateY(40vh) scale(0.32);
  }
  3.5714285714% {
    -webkit-transform: translateX(9vw) translateY(34vh) scale(0.67);
            transform: translateX(9vw) translateY(34vh) scale(0.67);
  }
  7.1428571429% {
    -webkit-transform: translateX(-21vw) translateY(-5vh) scale(0.64);
            transform: translateX(-21vw) translateY(-5vh) scale(0.64);
  }
  10.7142857143% {
    -webkit-transform: translateX(47vw) translateY(6vh) scale(0.76);
            transform: translateX(47vw) translateY(6vh) scale(0.76);
  }
  14.2857142857% {
    -webkit-transform: translateX(-16vw) translateY(-25vh) scale(0.93);
            transform: translateX(-16vw) translateY(-25vh) scale(0.93);
  }
  17.8571428571% {
    -webkit-transform: translateX(34vw) translateY(41vh) scale(0.36);
            transform: translateX(34vw) translateY(41vh) scale(0.36);
  }
  21.4285714286% {
    -webkit-transform: translateX(-34vw) translateY(38vh) scale(0.46);
            transform: translateX(-34vw) translateY(38vh) scale(0.46);
  }
  25% {
    -webkit-transform: translateX(-23vw) translateY(-42vh) scale(0.58);
            transform: translateX(-23vw) translateY(-42vh) scale(0.58);
  }
  28.5714285714% {
    -webkit-transform: translateX(6vw) translateY(-31vh) scale(0.93);
            transform: translateX(6vw) translateY(-31vh) scale(0.93);
  }
  32.1428571429% {
    -webkit-transform: translateX(-37vw) translateY(31vh) scale(0.47);
            transform: translateX(-37vw) translateY(31vh) scale(0.47);
  }
  35.7142857143% {
    -webkit-transform: translateX(49vw) translateY(-37vh) scale(0.58);
            transform: translateX(49vw) translateY(-37vh) scale(0.58);
  }
  39.2857142857% {
    -webkit-transform: translateX(13vw) translateY(-42vh) scale(0.86);
            transform: translateX(13vw) translateY(-42vh) scale(0.86);
  }
  42.8571428571% {
    -webkit-transform: translateX(34vw) translateY(41vh) scale(0.48);
            transform: translateX(34vw) translateY(41vh) scale(0.48);
  }
  46.4285714286% {
    -webkit-transform: translateX(-13vw) translateY(-13vh) scale(0.85);
            transform: translateX(-13vw) translateY(-13vh) scale(0.85);
  }
  50% {
    -webkit-transform: translateX(7vw) translateY(49vh) scale(0.39);
            transform: translateX(7vw) translateY(49vh) scale(0.39);
  }
  53.5714285714% {
    -webkit-transform: translateX(44vw) translateY(-15vh) scale(0.9);
            transform: translateX(44vw) translateY(-15vh) scale(0.9);
  }
  57.1428571429% {
    -webkit-transform: translateX(-2vw) translateY(-20vh) scale(0.27);
            transform: translateX(-2vw) translateY(-20vh) scale(0.27);
  }
  60.7142857143% {
    -webkit-transform: translateX(-3vw) translateY(34vh) scale(0.51);
            transform: translateX(-3vw) translateY(34vh) scale(0.51);
  }
  64.2857142857% {
    -webkit-transform: translateX(-40vw) translateY(-5vh) scale(0.39);
            transform: translateX(-40vw) translateY(-5vh) scale(0.39);
  }
  67.8571428571% {
    -webkit-transform: translateX(1vw) translateY(39vh) scale(0.53);
            transform: translateX(1vw) translateY(39vh) scale(0.53);
  }
  71.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(-12vh) scale(0.45);
            transform: translateX(-42vw) translateY(-12vh) scale(0.45);
  }
  75% {
    -webkit-transform: translateX(-17vw) translateY(-36vh) scale(0.63);
            transform: translateX(-17vw) translateY(-36vh) scale(0.63);
  }
  78.5714285714% {
    -webkit-transform: translateX(27vw) translateY(36vh) scale(0.92);
            transform: translateX(27vw) translateY(36vh) scale(0.92);
  }
  82.1428571429% {
    -webkit-transform: translateX(18vw) translateY(-5vh) scale(0.8);
            transform: translateX(18vw) translateY(-5vh) scale(0.8);
  }
  85.7142857143% {
    -webkit-transform: translateX(40vw) translateY(13vh) scale(0.92);
            transform: translateX(40vw) translateY(13vh) scale(0.92);
  }
  89.2857142857% {
    -webkit-transform: translateX(3vw) translateY(-4vh) scale(0.33);
            transform: translateX(3vw) translateY(-4vh) scale(0.33);
  }
  92.8571428571% {
    -webkit-transform: translateX(-31vw) translateY(43vh) scale(0.71);
            transform: translateX(-31vw) translateY(43vh) scale(0.71);
  }
  96.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(-18vh) scale(0.64);
            transform: translateX(-42vw) translateY(-18vh) scale(0.64);
  }
  100% {
    -webkit-transform: translateX(24vw) translateY(14vh) scale(0.67);
            transform: translateX(24vw) translateY(14vh) scale(0.67);
  }
}
.firefly:nth-child(3) {
  -webkit-animation-name: move3;
          animation-name: move3;
}
.firefly:nth-child(3)::before {
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}
.firefly:nth-child(3)::after {
  -webkit-animation-duration: 9s, 7991ms;
          animation-duration: 9s, 7991ms;
  -webkit-animation-delay: 0ms, 1922ms;
          animation-delay: 0ms, 1922ms;
}

@-webkit-keyframes move3 {
  0% {
    -webkit-transform: translateX(-22vw) translateY(24vh) scale(0.62);
            transform: translateX(-22vw) translateY(24vh) scale(0.62);
  }
  3.5714285714% {
    -webkit-transform: translateX(-44vw) translateY(37vh) scale(0.67);
            transform: translateX(-44vw) translateY(37vh) scale(0.67);
  }
  7.1428571429% {
    -webkit-transform: translateX(-47vw) translateY(46vh) scale(0.91);
            transform: translateX(-47vw) translateY(46vh) scale(0.91);
  }
  10.7142857143% {
    -webkit-transform: translateX(39vw) translateY(15vh) scale(0.67);
            transform: translateX(39vw) translateY(15vh) scale(0.67);
  }
  14.2857142857% {
    -webkit-transform: translateX(7vw) translateY(44vh) scale(0.73);
            transform: translateX(7vw) translateY(44vh) scale(0.73);
  }
  17.8571428571% {
    -webkit-transform: translateX(40vw) translateY(11vh) scale(0.56);
            transform: translateX(40vw) translateY(11vh) scale(0.56);
  }
  21.4285714286% {
    -webkit-transform: translateX(23vw) translateY(-30vh) scale(0.98);
            transform: translateX(23vw) translateY(-30vh) scale(0.98);
  }
  25% {
    -webkit-transform: translateX(-14vw) translateY(-47vh) scale(0.4);
            transform: translateX(-14vw) translateY(-47vh) scale(0.4);
  }
  28.5714285714% {
    -webkit-transform: translateX(31vw) translateY(30vh) scale(0.78);
            transform: translateX(31vw) translateY(30vh) scale(0.78);
  }
  32.1428571429% {
    -webkit-transform: translateX(-10vw) translateY(-22vh) scale(0.7);
            transform: translateX(-10vw) translateY(-22vh) scale(0.7);
  }
  35.7142857143% {
    -webkit-transform: translateX(-31vw) translateY(2vh) scale(0.87);
            transform: translateX(-31vw) translateY(2vh) scale(0.87);
  }
  39.2857142857% {
    -webkit-transform: translateX(45vw) translateY(-3vh) scale(0.33);
            transform: translateX(45vw) translateY(-3vh) scale(0.33);
  }
  42.8571428571% {
    -webkit-transform: translateX(-7vw) translateY(-30vh) scale(0.88);
            transform: translateX(-7vw) translateY(-30vh) scale(0.88);
  }
  46.4285714286% {
    -webkit-transform: translateX(8vw) translateY(22vh) scale(0.51);
            transform: translateX(8vw) translateY(22vh) scale(0.51);
  }
  50% {
    -webkit-transform: translateX(31vw) translateY(25vh) scale(0.31);
            transform: translateX(31vw) translateY(25vh) scale(0.31);
  }
  53.5714285714% {
    -webkit-transform: translateX(47vw) translateY(13vh) scale(0.76);
            transform: translateX(47vw) translateY(13vh) scale(0.76);
  }
  57.1428571429% {
    -webkit-transform: translateX(-19vw) translateY(7vh) scale(0.36);
            transform: translateX(-19vw) translateY(7vh) scale(0.36);
  }
  60.7142857143% {
    -webkit-transform: translateX(11vw) translateY(6vh) scale(0.83);
            transform: translateX(11vw) translateY(6vh) scale(0.83);
  }
  64.2857142857% {
    -webkit-transform: translateX(48vw) translateY(-38vh) scale(0.62);
            transform: translateX(48vw) translateY(-38vh) scale(0.62);
  }
  67.8571428571% {
    -webkit-transform: translateX(41vw) translateY(-18vh) scale(0.68);
            transform: translateX(41vw) translateY(-18vh) scale(0.68);
  }
  71.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(50vh) scale(0.34);
            transform: translateX(-42vw) translateY(50vh) scale(0.34);
  }
  75% {
    -webkit-transform: translateX(-31vw) translateY(-15vh) scale(0.51);
            transform: translateX(-31vw) translateY(-15vh) scale(0.51);
  }
  78.5714285714% {
    -webkit-transform: translateX(-41vw) translateY(9vh) scale(0.91);
            transform: translateX(-41vw) translateY(9vh) scale(0.91);
  }
  82.1428571429% {
    -webkit-transform: translateX(-39vw) translateY(16vh) scale(0.45);
            transform: translateX(-39vw) translateY(16vh) scale(0.45);
  }
  85.7142857143% {
    -webkit-transform: translateX(-44vw) translateY(9vh) scale(0.83);
            transform: translateX(-44vw) translateY(9vh) scale(0.83);
  }
  89.2857142857% {
    -webkit-transform: translateX(-41vw) translateY(8vh) scale(0.66);
            transform: translateX(-41vw) translateY(8vh) scale(0.66);
  }
  92.8571428571% {
    -webkit-transform: translateX(-44vw) translateY(42vh) scale(0.56);
            transform: translateX(-44vw) translateY(42vh) scale(0.56);
  }
  96.4285714286% {
    -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.86);
            transform: translateX(-31vw) translateY(-31vh) scale(0.86);
  }
  100% {
    -webkit-transform: translateX(-4vw) translateY(-8vh) scale(0.42);
            transform: translateX(-4vw) translateY(-8vh) scale(0.42);
  }
}

@keyframes move3 {
  0% {
    -webkit-transform: translateX(-22vw) translateY(24vh) scale(0.62);
            transform: translateX(-22vw) translateY(24vh) scale(0.62);
  }
  3.5714285714% {
    -webkit-transform: translateX(-44vw) translateY(37vh) scale(0.67);
            transform: translateX(-44vw) translateY(37vh) scale(0.67);
  }
  7.1428571429% {
    -webkit-transform: translateX(-47vw) translateY(46vh) scale(0.91);
            transform: translateX(-47vw) translateY(46vh) scale(0.91);
  }
  10.7142857143% {
    -webkit-transform: translateX(39vw) translateY(15vh) scale(0.67);
            transform: translateX(39vw) translateY(15vh) scale(0.67);
  }
  14.2857142857% {
    -webkit-transform: translateX(7vw) translateY(44vh) scale(0.73);
            transform: translateX(7vw) translateY(44vh) scale(0.73);
  }
  17.8571428571% {
    -webkit-transform: translateX(40vw) translateY(11vh) scale(0.56);
            transform: translateX(40vw) translateY(11vh) scale(0.56);
  }
  21.4285714286% {
    -webkit-transform: translateX(23vw) translateY(-30vh) scale(0.98);
            transform: translateX(23vw) translateY(-30vh) scale(0.98);
  }
  25% {
    -webkit-transform: translateX(-14vw) translateY(-47vh) scale(0.4);
            transform: translateX(-14vw) translateY(-47vh) scale(0.4);
  }
  28.5714285714% {
    -webkit-transform: translateX(31vw) translateY(30vh) scale(0.78);
            transform: translateX(31vw) translateY(30vh) scale(0.78);
  }
  32.1428571429% {
    -webkit-transform: translateX(-10vw) translateY(-22vh) scale(0.7);
            transform: translateX(-10vw) translateY(-22vh) scale(0.7);
  }
  35.7142857143% {
    -webkit-transform: translateX(-31vw) translateY(2vh) scale(0.87);
            transform: translateX(-31vw) translateY(2vh) scale(0.87);
  }
  39.2857142857% {
    -webkit-transform: translateX(45vw) translateY(-3vh) scale(0.33);
            transform: translateX(45vw) translateY(-3vh) scale(0.33);
  }
  42.8571428571% {
    -webkit-transform: translateX(-7vw) translateY(-30vh) scale(0.88);
            transform: translateX(-7vw) translateY(-30vh) scale(0.88);
  }
  46.4285714286% {
    -webkit-transform: translateX(8vw) translateY(22vh) scale(0.51);
            transform: translateX(8vw) translateY(22vh) scale(0.51);
  }
  50% {
    -webkit-transform: translateX(31vw) translateY(25vh) scale(0.31);
            transform: translateX(31vw) translateY(25vh) scale(0.31);
  }
  53.5714285714% {
    -webkit-transform: translateX(47vw) translateY(13vh) scale(0.76);
            transform: translateX(47vw) translateY(13vh) scale(0.76);
  }
  57.1428571429% {
    -webkit-transform: translateX(-19vw) translateY(7vh) scale(0.36);
            transform: translateX(-19vw) translateY(7vh) scale(0.36);
  }
  60.7142857143% {
    -webkit-transform: translateX(11vw) translateY(6vh) scale(0.83);
            transform: translateX(11vw) translateY(6vh) scale(0.83);
  }
  64.2857142857% {
    -webkit-transform: translateX(48vw) translateY(-38vh) scale(0.62);
            transform: translateX(48vw) translateY(-38vh) scale(0.62);
  }
  67.8571428571% {
    -webkit-transform: translateX(41vw) translateY(-18vh) scale(0.68);
            transform: translateX(41vw) translateY(-18vh) scale(0.68);
  }
  71.4285714286% {
    -webkit-transform: translateX(-42vw) translateY(50vh) scale(0.34);
            transform: translateX(-42vw) translateY(50vh) scale(0.34);
  }
  75% {
    -webkit-transform: translateX(-31vw) translateY(-15vh) scale(0.51);
            transform: translateX(-31vw) translateY(-15vh) scale(0.51);
  }
  78.5714285714% {
    -webkit-transform: translateX(-41vw) translateY(9vh) scale(0.91);
            transform: translateX(-41vw) translateY(9vh) scale(0.91);
  }
  82.1428571429% {
    -webkit-transform: translateX(-39vw) translateY(16vh) scale(0.45);
            transform: translateX(-39vw) translateY(16vh) scale(0.45);
  }
  85.7142857143% {
    -webkit-transform: translateX(-44vw) translateY(9vh) scale(0.83);
            transform: translateX(-44vw) translateY(9vh) scale(0.83);
  }
  89.2857142857% {
    -webkit-transform: translateX(-41vw) translateY(8vh) scale(0.66);
            transform: translateX(-41vw) translateY(8vh) scale(0.66);
  }
  92.8571428571% {
    -webkit-transform: translateX(-44vw) translateY(42vh) scale(0.56);
            transform: translateX(-44vw) translateY(42vh) scale(0.56);
  }
  96.4285714286% {
    -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.86);
            transform: translateX(-31vw) translateY(-31vh) scale(0.86);
  }
  100% {
    -webkit-transform: translateX(-4vw) translateY(-8vh) scale(0.42);
            transform: translateX(-4vw) translateY(-8vh) scale(0.42);
  }
}
.firefly:nth-child(4) {
  -webkit-animation-name: move4;
          animation-name: move4;
}
.firefly:nth-child(4)::before {
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}
.firefly:nth-child(4)::after {
  -webkit-animation-duration: 17s, 5968ms;
          animation-duration: 17s, 5968ms;
  -webkit-animation-delay: 0ms, 1513ms;
          animation-delay: 0ms, 1513ms;
}

@-webkit-keyframes move4 {
  0% {
    -webkit-transform: translateX(45vw) translateY(13vh) scale(0.5);
            transform: translateX(45vw) translateY(13vh) scale(0.5);
  }
  5.2631578947% {
    -webkit-transform: translateX(37vw) translateY(14vh) scale(0.98);
            transform: translateX(37vw) translateY(14vh) scale(0.98);
  }
  10.5263157895% {
    -webkit-transform: translateX(-6vw) translateY(-16vh) scale(0.7);
            transform: translateX(-6vw) translateY(-16vh) scale(0.7);
  }
  15.7894736842% {
    -webkit-transform: translateX(-24vw) translateY(-47vh) scale(0.29);
            transform: translateX(-24vw) translateY(-47vh) scale(0.29);
  }
  21.0526315789% {
    -webkit-transform: translateX(-22vw) translateY(-34vh) scale(0.63);
            transform: translateX(-22vw) translateY(-34vh) scale(0.63);
  }
  26.3157894737% {
    -webkit-transform: translateX(-11vw) translateY(-16vh) scale(0.49);
            transform: translateX(-11vw) translateY(-16vh) scale(0.49);
  }
  31.5789473684% {
    -webkit-transform: translateX(-11vw) translateY(7vh) scale(0.45);
            transform: translateX(-11vw) translateY(7vh) scale(0.45);
  }
  36.8421052632% {
    -webkit-transform: translateX(13vw) translateY(-6vh) scale(0.34);
            transform: translateX(13vw) translateY(-6vh) scale(0.34);
  }
  42.1052631579% {
    -webkit-transform: translateX(-33vw) translateY(-9vh) scale(0.37);
            transform: translateX(-33vw) translateY(-9vh) scale(0.37);
  }
  47.3684210526% {
    -webkit-transform: translateX(5vw) translateY(25vh) scale(0.53);
            transform: translateX(5vw) translateY(25vh) scale(0.53);
  }
  52.6315789474% {
    -webkit-transform: translateX(27vw) translateY(14vh) scale(0.74);
            transform: translateX(27vw) translateY(14vh) scale(0.74);
  }
  57.8947368421% {
    -webkit-transform: translateX(-26vw) translateY(-28vh) scale(0.64);
            transform: translateX(-26vw) translateY(-28vh) scale(0.64);
  }
  63.1578947368% {
    -webkit-transform: translateX(-39vw) translateY(-17vh) scale(0.46);
            transform: translateX(-39vw) translateY(-17vh) scale(0.46);
  }
  68.4210526316% {
    -webkit-transform: translateX(-23vw) translateY(17vh) scale(0.37);
            transform: translateX(-23vw) translateY(17vh) scale(0.37);
  }
  73.6842105263% {
    -webkit-transform: translateX(9vw) translateY(-6vh) scale(0.69);
            transform: translateX(9vw) translateY(-6vh) scale(0.69);
  }
  78.9473684211% {
    -webkit-transform: translateX(-41vw) translateY(-42vh) scale(0.75);
            transform: translateX(-41vw) translateY(-42vh) scale(0.75);
  }
  84.2105263158% {
    -webkit-transform: translateX(34vw) translateY(1vh) scale(0.86);
            transform: translateX(34vw) translateY(1vh) scale(0.86);
  }
  89.4736842105% {
    -webkit-transform: translateX(22vw) translateY(-26vh) scale(0.91);
            transform: translateX(22vw) translateY(-26vh) scale(0.91);
  }
  94.7368421053% {
    -webkit-transform: translateX(-26vw) translateY(13vh) scale(0.49);
            transform: translateX(-26vw) translateY(13vh) scale(0.49);
  }
  100% {
    -webkit-transform: translateX(1vw) translateY(-1vh) scale(0.76);
            transform: translateX(1vw) translateY(-1vh) scale(0.76);
  }
}

@keyframes move4 {
  0% {
    -webkit-transform: translateX(45vw) translateY(13vh) scale(0.5);
            transform: translateX(45vw) translateY(13vh) scale(0.5);
  }
  5.2631578947% {
    -webkit-transform: translateX(37vw) translateY(14vh) scale(0.98);
            transform: translateX(37vw) translateY(14vh) scale(0.98);
  }
  10.5263157895% {
    -webkit-transform: translateX(-6vw) translateY(-16vh) scale(0.7);
            transform: translateX(-6vw) translateY(-16vh) scale(0.7);
  }
  15.7894736842% {
    -webkit-transform: translateX(-24vw) translateY(-47vh) scale(0.29);
            transform: translateX(-24vw) translateY(-47vh) scale(0.29);
  }
  21.0526315789% {
    -webkit-transform: translateX(-22vw) translateY(-34vh) scale(0.63);
            transform: translateX(-22vw) translateY(-34vh) scale(0.63);
  }
  26.3157894737% {
    -webkit-transform: translateX(-11vw) translateY(-16vh) scale(0.49);
            transform: translateX(-11vw) translateY(-16vh) scale(0.49);
  }
  31.5789473684% {
    -webkit-transform: translateX(-11vw) translateY(7vh) scale(0.45);
            transform: translateX(-11vw) translateY(7vh) scale(0.45);
  }
  36.8421052632% {
    -webkit-transform: translateX(13vw) translateY(-6vh) scale(0.34);
            transform: translateX(13vw) translateY(-6vh) scale(0.34);
  }
  42.1052631579% {
    -webkit-transform: translateX(-33vw) translateY(-9vh) scale(0.37);
            transform: translateX(-33vw) translateY(-9vh) scale(0.37);
  }
  47.3684210526% {
    -webkit-transform: translateX(5vw) translateY(25vh) scale(0.53);
            transform: translateX(5vw) translateY(25vh) scale(0.53);
  }
  52.6315789474% {
    -webkit-transform: translateX(27vw) translateY(14vh) scale(0.74);
            transform: translateX(27vw) translateY(14vh) scale(0.74);
  }
  57.8947368421% {
    -webkit-transform: translateX(-26vw) translateY(-28vh) scale(0.64);
            transform: translateX(-26vw) translateY(-28vh) scale(0.64);
  }
  63.1578947368% {
    -webkit-transform: translateX(-39vw) translateY(-17vh) scale(0.46);
            transform: translateX(-39vw) translateY(-17vh) scale(0.46);
  }
  68.4210526316% {
    -webkit-transform: translateX(-23vw) translateY(17vh) scale(0.37);
            transform: translateX(-23vw) translateY(17vh) scale(0.37);
  }
  73.6842105263% {
    -webkit-transform: translateX(9vw) translateY(-6vh) scale(0.69);
            transform: translateX(9vw) translateY(-6vh) scale(0.69);
  }
  78.9473684211% {
    -webkit-transform: translateX(-41vw) translateY(-42vh) scale(0.75);
            transform: translateX(-41vw) translateY(-42vh) scale(0.75);
  }
  84.2105263158% {
    -webkit-transform: translateX(34vw) translateY(1vh) scale(0.86);
            transform: translateX(34vw) translateY(1vh) scale(0.86);
  }
  89.4736842105% {
    -webkit-transform: translateX(22vw) translateY(-26vh) scale(0.91);
            transform: translateX(22vw) translateY(-26vh) scale(0.91);
  }
  94.7368421053% {
    -webkit-transform: translateX(-26vw) translateY(13vh) scale(0.49);
            transform: translateX(-26vw) translateY(13vh) scale(0.49);
  }
  100% {
    -webkit-transform: translateX(1vw) translateY(-1vh) scale(0.76);
            transform: translateX(1vw) translateY(-1vh) scale(0.76);
  }
}
.firefly:nth-child(5) {
  -webkit-animation-name: move5;
          animation-name: move5;
}
.firefly:nth-child(5)::before {
  -webkit-animation-duration: 13s;
          animation-duration: 13s;
}
.firefly:nth-child(5)::after {
  -webkit-animation-duration: 13s, 9409ms;
          animation-duration: 13s, 9409ms;
  -webkit-animation-delay: 0ms, 3495ms;
          animation-delay: 0ms, 3495ms;
}

@-webkit-keyframes move5 {
  0% {
    -webkit-transform: translateX(34vw) translateY(18vh) scale(0.64);
            transform: translateX(34vw) translateY(18vh) scale(0.64);
  }
  4.1666666667% {
    -webkit-transform: translateX(34vw) translateY(11vh) scale(0.39);
            transform: translateX(34vw) translateY(11vh) scale(0.39);
  }
  8.3333333333% {
    -webkit-transform: translateX(-37vw) translateY(-43vh) scale(0.47);
            transform: translateX(-37vw) translateY(-43vh) scale(0.47);
  }
  12.5% {
    -webkit-transform: translateX(17vw) translateY(-5vh) scale(0.49);
            transform: translateX(17vw) translateY(-5vh) scale(0.49);
  }
  16.6666666667% {
    -webkit-transform: translateX(30vw) translateY(7vh) scale(0.47);
            transform: translateX(30vw) translateY(7vh) scale(0.47);
  }
  20.8333333333% {
    -webkit-transform: translateX(-46vw) translateY(-32vh) scale(0.76);
            transform: translateX(-46vw) translateY(-32vh) scale(0.76);
  }
  25% {
    -webkit-transform: translateX(-20vw) translateY(28vh) scale(0.7);
            transform: translateX(-20vw) translateY(28vh) scale(0.7);
  }
  29.1666666667% {
    -webkit-transform: translateX(-16vw) translateY(-5vh) scale(0.84);
            transform: translateX(-16vw) translateY(-5vh) scale(0.84);
  }
  33.3333333333% {
    -webkit-transform: translateX(-17vw) translateY(-41vh) scale(0.42);
            transform: translateX(-17vw) translateY(-41vh) scale(0.42);
  }
  37.5% {
    -webkit-transform: translateX(19vw) translateY(-20vh) scale(0.81);
            transform: translateX(19vw) translateY(-20vh) scale(0.81);
  }
  41.6666666667% {
    -webkit-transform: translateX(12vw) translateY(15vh) scale(0.75);
            transform: translateX(12vw) translateY(15vh) scale(0.75);
  }
  45.8333333333% {
    -webkit-transform: translateX(30vw) translateY(-32vh) scale(0.47);
            transform: translateX(30vw) translateY(-32vh) scale(0.47);
  }
  50% {
    -webkit-transform: translateX(-40vw) translateY(-38vh) scale(0.59);
            transform: translateX(-40vw) translateY(-38vh) scale(0.59);
  }
  54.1666666667% {
    -webkit-transform: translateX(4vw) translateY(44vh) scale(0.82);
            transform: translateX(4vw) translateY(44vh) scale(0.82);
  }
  58.3333333333% {
    -webkit-transform: translateX(44vw) translateY(-14vh) scale(0.55);
            transform: translateX(44vw) translateY(-14vh) scale(0.55);
  }
  62.5% {
    -webkit-transform: translateX(-15vw) translateY(39vh) scale(0.33);
            transform: translateX(-15vw) translateY(39vh) scale(0.33);
  }
  66.6666666667% {
    -webkit-transform: translateX(-28vw) translateY(-30vh) scale(0.29);
            transform: translateX(-28vw) translateY(-30vh) scale(0.29);
  }
  70.8333333333% {
    -webkit-transform: translateX(40vw) translateY(27vh) scale(0.61);
            transform: translateX(40vw) translateY(27vh) scale(0.61);
  }
  75% {
    -webkit-transform: translateX(22vw) translateY(-21vh) scale(0.53);
            transform: translateX(22vw) translateY(-21vh) scale(0.53);
  }
  79.1666666667% {
    -webkit-transform: translateX(-49vw) translateY(21vh) scale(0.64);
            transform: translateX(-49vw) translateY(21vh) scale(0.64);
  }
  83.3333333333% {
    -webkit-transform: translateX(7vw) translateY(14vh) scale(0.81);
            transform: translateX(7vw) translateY(14vh) scale(0.81);
  }
  87.5% {
    -webkit-transform: translateX(12vw) translateY(14vh) scale(0.8);
            transform: translateX(12vw) translateY(14vh) scale(0.8);
  }
  91.6666666667% {
    -webkit-transform: translateX(-44vw) translateY(-42vh) scale(0.49);
            transform: translateX(-44vw) translateY(-42vh) scale(0.49);
  }
  95.8333333333% {
    -webkit-transform: translateX(43vw) translateY(41vh) scale(0.6);
            transform: translateX(43vw) translateY(41vh) scale(0.6);
  }
  100% {
    -webkit-transform: translateX(6vw) translateY(-29vh) scale(0.7);
            transform: translateX(6vw) translateY(-29vh) scale(0.7);
  }
}

@keyframes move5 {
  0% {
    -webkit-transform: translateX(34vw) translateY(18vh) scale(0.64);
            transform: translateX(34vw) translateY(18vh) scale(0.64);
  }
  4.1666666667% {
    -webkit-transform: translateX(34vw) translateY(11vh) scale(0.39);
            transform: translateX(34vw) translateY(11vh) scale(0.39);
  }
  8.3333333333% {
    -webkit-transform: translateX(-37vw) translateY(-43vh) scale(0.47);
            transform: translateX(-37vw) translateY(-43vh) scale(0.47);
  }
  12.5% {
    -webkit-transform: translateX(17vw) translateY(-5vh) scale(0.49);
            transform: translateX(17vw) translateY(-5vh) scale(0.49);
  }
  16.6666666667% {
    -webkit-transform: translateX(30vw) translateY(7vh) scale(0.47);
            transform: translateX(30vw) translateY(7vh) scale(0.47);
  }
  20.8333333333% {
    -webkit-transform: translateX(-46vw) translateY(-32vh) scale(0.76);
            transform: translateX(-46vw) translateY(-32vh) scale(0.76);
  }
  25% {
    -webkit-transform: translateX(-20vw) translateY(28vh) scale(0.7);
            transform: translateX(-20vw) translateY(28vh) scale(0.7);
  }
  29.1666666667% {
    -webkit-transform: translateX(-16vw) translateY(-5vh) scale(0.84);
            transform: translateX(-16vw) translateY(-5vh) scale(0.84);
  }
  33.3333333333% {
    -webkit-transform: translateX(-17vw) translateY(-41vh) scale(0.42);
            transform: translateX(-17vw) translateY(-41vh) scale(0.42);
  }
  37.5% {
    -webkit-transform: translateX(19vw) translateY(-20vh) scale(0.81);
            transform: translateX(19vw) translateY(-20vh) scale(0.81);
  }
  41.6666666667% {
    -webkit-transform: translateX(12vw) translateY(15vh) scale(0.75);
            transform: translateX(12vw) translateY(15vh) scale(0.75);
  }
  45.8333333333% {
    -webkit-transform: translateX(30vw) translateY(-32vh) scale(0.47);
            transform: translateX(30vw) translateY(-32vh) scale(0.47);
  }
  50% {
    -webkit-transform: translateX(-40vw) translateY(-38vh) scale(0.59);
            transform: translateX(-40vw) translateY(-38vh) scale(0.59);
  }
  54.1666666667% {
    -webkit-transform: translateX(4vw) translateY(44vh) scale(0.82);
            transform: translateX(4vw) translateY(44vh) scale(0.82);
  }
  58.3333333333% {
    -webkit-transform: translateX(44vw) translateY(-14vh) scale(0.55);
            transform: translateX(44vw) translateY(-14vh) scale(0.55);
  }
  62.5% {
    -webkit-transform: translateX(-15vw) translateY(39vh) scale(0.33);
            transform: translateX(-15vw) translateY(39vh) scale(0.33);
  }
  66.6666666667% {
    -webkit-transform: translateX(-28vw) translateY(-30vh) scale(0.29);
            transform: translateX(-28vw) translateY(-30vh) scale(0.29);
  }
  70.8333333333% {
    -webkit-transform: translateX(40vw) translateY(27vh) scale(0.61);
            transform: translateX(40vw) translateY(27vh) scale(0.61);
  }
  75% {
    -webkit-transform: translateX(22vw) translateY(-21vh) scale(0.53);
            transform: translateX(22vw) translateY(-21vh) scale(0.53);
  }
  79.1666666667% {
    -webkit-transform: translateX(-49vw) translateY(21vh) scale(0.64);
            transform: translateX(-49vw) translateY(21vh) scale(0.64);
  }
  83.3333333333% {
    -webkit-transform: translateX(7vw) translateY(14vh) scale(0.81);
            transform: translateX(7vw) translateY(14vh) scale(0.81);
  }
  87.5% {
    -webkit-transform: translateX(12vw) translateY(14vh) scale(0.8);
            transform: translateX(12vw) translateY(14vh) scale(0.8);
  }
  91.6666666667% {
    -webkit-transform: translateX(-44vw) translateY(-42vh) scale(0.49);
            transform: translateX(-44vw) translateY(-42vh) scale(0.49);
  }
  95.8333333333% {
    -webkit-transform: translateX(43vw) translateY(41vh) scale(0.6);
            transform: translateX(43vw) translateY(41vh) scale(0.6);
  }
  100% {
    -webkit-transform: translateX(6vw) translateY(-29vh) scale(0.7);
            transform: translateX(6vw) translateY(-29vh) scale(0.7);
  }
}
.firefly:nth-child(6) {
  -webkit-animation-name: move6;
          animation-name: move6;
}
.firefly:nth-child(6)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.firefly:nth-child(6)::after {
  -webkit-animation-duration: 15s, 10229ms;
          animation-duration: 15s, 10229ms;
  -webkit-animation-delay: 0ms, 7081ms;
          animation-delay: 0ms, 7081ms;
}

@-webkit-keyframes move6 {
  0% {
    -webkit-transform: translateX(23vw) translateY(18vh) scale(0.76);
            transform: translateX(23vw) translateY(18vh) scale(0.76);
  }
  4.1666666667% {
    -webkit-transform: translateX(-26vw) translateY(6vh) scale(0.41);
            transform: translateX(-26vw) translateY(6vh) scale(0.41);
  }
  8.3333333333% {
    -webkit-transform: translateX(-1vw) translateY(-18vh) scale(0.63);
            transform: translateX(-1vw) translateY(-18vh) scale(0.63);
  }
  12.5% {
    -webkit-transform: translateX(22vw) translateY(43vh) scale(0.63);
            transform: translateX(22vw) translateY(43vh) scale(0.63);
  }
  16.6666666667% {
    -webkit-transform: translateX(25vw) translateY(48vh) scale(0.93);
            transform: translateX(25vw) translateY(48vh) scale(0.93);
  }
  20.8333333333% {
    -webkit-transform: translateX(3vw) translateY(-29vh) scale(0.33);
            transform: translateX(3vw) translateY(-29vh) scale(0.33);
  }
  25% {
    -webkit-transform: translateX(26vw) translateY(0vh) scale(0.86);
            transform: translateX(26vw) translateY(0vh) scale(0.86);
  }
  29.1666666667% {
    -webkit-transform: translateX(-33vw) translateY(5vh) scale(0.51);
            transform: translateX(-33vw) translateY(5vh) scale(0.51);
  }
  33.3333333333% {
    -webkit-transform: translateX(4vw) translateY(-9vh) scale(0.39);
            transform: translateX(4vw) translateY(-9vh) scale(0.39);
  }
  37.5% {
    -webkit-transform: translateX(26vw) translateY(12vh) scale(0.65);
            transform: translateX(26vw) translateY(12vh) scale(0.65);
  }
  41.6666666667% {
    -webkit-transform: translateX(31vw) translateY(18vh) scale(0.42);
            transform: translateX(31vw) translateY(18vh) scale(0.42);
  }
  45.8333333333% {
    -webkit-transform: translateX(25vw) translateY(-45vh) scale(0.9);
            transform: translateX(25vw) translateY(-45vh) scale(0.9);
  }
  50% {
    -webkit-transform: translateX(-11vw) translateY(49vh) scale(0.46);
            transform: translateX(-11vw) translateY(49vh) scale(0.46);
  }
  54.1666666667% {
    -webkit-transform: translateX(-45vw) translateY(48vh) scale(0.81);
            transform: translateX(-45vw) translateY(48vh) scale(0.81);
  }
  58.3333333333% {
    -webkit-transform: translateX(46vw) translateY(5vh) scale(0.57);
            transform: translateX(46vw) translateY(5vh) scale(0.57);
  }
  62.5% {
    -webkit-transform: translateX(35vw) translateY(-47vh) scale(0.56);
            transform: translateX(35vw) translateY(-47vh) scale(0.56);
  }
  66.6666666667% {
    -webkit-transform: translateX(22vw) translateY(-44vh) scale(0.83);
            transform: translateX(22vw) translateY(-44vh) scale(0.83);
  }
  70.8333333333% {
    -webkit-transform: translateX(-40vw) translateY(-11vh) scale(0.73);
            transform: translateX(-40vw) translateY(-11vh) scale(0.73);
  }
  75% {
    -webkit-transform: translateX(-19vw) translateY(13vh) scale(0.69);
            transform: translateX(-19vw) translateY(13vh) scale(0.69);
  }
  79.1666666667% {
    -webkit-transform: translateX(-9vw) translateY(-16vh) scale(0.83);
            transform: translateX(-9vw) translateY(-16vh) scale(0.83);
  }
  83.3333333333% {
    -webkit-transform: translateX(33vw) translateY(21vh) scale(0.67);
            transform: translateX(33vw) translateY(21vh) scale(0.67);
  }
  87.5% {
    -webkit-transform: translateX(-36vw) translateY(27vh) scale(0.79);
            transform: translateX(-36vw) translateY(27vh) scale(0.79);
  }
  91.6666666667% {
    -webkit-transform: translateX(5vw) translateY(17vh) scale(0.56);
            transform: translateX(5vw) translateY(17vh) scale(0.56);
  }
  95.8333333333% {
    -webkit-transform: translateX(29vw) translateY(-10vh) scale(0.51);
            transform: translateX(29vw) translateY(-10vh) scale(0.51);
  }
  100% {
    -webkit-transform: translateX(-1vw) translateY(-14vh) scale(0.29);
            transform: translateX(-1vw) translateY(-14vh) scale(0.29);
  }
}

@keyframes move6 {
  0% {
    -webkit-transform: translateX(23vw) translateY(18vh) scale(0.76);
            transform: translateX(23vw) translateY(18vh) scale(0.76);
  }
  4.1666666667% {
    -webkit-transform: translateX(-26vw) translateY(6vh) scale(0.41);
            transform: translateX(-26vw) translateY(6vh) scale(0.41);
  }
  8.3333333333% {
    -webkit-transform: translateX(-1vw) translateY(-18vh) scale(0.63);
            transform: translateX(-1vw) translateY(-18vh) scale(0.63);
  }
  12.5% {
    -webkit-transform: translateX(22vw) translateY(43vh) scale(0.63);
            transform: translateX(22vw) translateY(43vh) scale(0.63);
  }
  16.6666666667% {
    -webkit-transform: translateX(25vw) translateY(48vh) scale(0.93);
            transform: translateX(25vw) translateY(48vh) scale(0.93);
  }
  20.8333333333% {
    -webkit-transform: translateX(3vw) translateY(-29vh) scale(0.33);
            transform: translateX(3vw) translateY(-29vh) scale(0.33);
  }
  25% {
    -webkit-transform: translateX(26vw) translateY(0vh) scale(0.86);
            transform: translateX(26vw) translateY(0vh) scale(0.86);
  }
  29.1666666667% {
    -webkit-transform: translateX(-33vw) translateY(5vh) scale(0.51);
            transform: translateX(-33vw) translateY(5vh) scale(0.51);
  }
  33.3333333333% {
    -webkit-transform: translateX(4vw) translateY(-9vh) scale(0.39);
            transform: translateX(4vw) translateY(-9vh) scale(0.39);
  }
  37.5% {
    -webkit-transform: translateX(26vw) translateY(12vh) scale(0.65);
            transform: translateX(26vw) translateY(12vh) scale(0.65);
  }
  41.6666666667% {
    -webkit-transform: translateX(31vw) translateY(18vh) scale(0.42);
            transform: translateX(31vw) translateY(18vh) scale(0.42);
  }
  45.8333333333% {
    -webkit-transform: translateX(25vw) translateY(-45vh) scale(0.9);
            transform: translateX(25vw) translateY(-45vh) scale(0.9);
  }
  50% {
    -webkit-transform: translateX(-11vw) translateY(49vh) scale(0.46);
            transform: translateX(-11vw) translateY(49vh) scale(0.46);
  }
  54.1666666667% {
    -webkit-transform: translateX(-45vw) translateY(48vh) scale(0.81);
            transform: translateX(-45vw) translateY(48vh) scale(0.81);
  }
  58.3333333333% {
    -webkit-transform: translateX(46vw) translateY(5vh) scale(0.57);
            transform: translateX(46vw) translateY(5vh) scale(0.57);
  }
  62.5% {
    -webkit-transform: translateX(35vw) translateY(-47vh) scale(0.56);
            transform: translateX(35vw) translateY(-47vh) scale(0.56);
  }
  66.6666666667% {
    -webkit-transform: translateX(22vw) translateY(-44vh) scale(0.83);
            transform: translateX(22vw) translateY(-44vh) scale(0.83);
  }
  70.8333333333% {
    -webkit-transform: translateX(-40vw) translateY(-11vh) scale(0.73);
            transform: translateX(-40vw) translateY(-11vh) scale(0.73);
  }
  75% {
    -webkit-transform: translateX(-19vw) translateY(13vh) scale(0.69);
            transform: translateX(-19vw) translateY(13vh) scale(0.69);
  }
  79.1666666667% {
    -webkit-transform: translateX(-9vw) translateY(-16vh) scale(0.83);
            transform: translateX(-9vw) translateY(-16vh) scale(0.83);
  }
  83.3333333333% {
    -webkit-transform: translateX(33vw) translateY(21vh) scale(0.67);
            transform: translateX(33vw) translateY(21vh) scale(0.67);
  }
  87.5% {
    -webkit-transform: translateX(-36vw) translateY(27vh) scale(0.79);
            transform: translateX(-36vw) translateY(27vh) scale(0.79);
  }
  91.6666666667% {
    -webkit-transform: translateX(5vw) translateY(17vh) scale(0.56);
            transform: translateX(5vw) translateY(17vh) scale(0.56);
  }
  95.8333333333% {
    -webkit-transform: translateX(29vw) translateY(-10vh) scale(0.51);
            transform: translateX(29vw) translateY(-10vh) scale(0.51);
  }
  100% {
    -webkit-transform: translateX(-1vw) translateY(-14vh) scale(0.29);
            transform: translateX(-1vw) translateY(-14vh) scale(0.29);
  }
}
.firefly:nth-child(7) {
  -webkit-animation-name: move7;
          animation-name: move7;
}
.firefly:nth-child(7)::before {
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}
.firefly:nth-child(7)::after {
  -webkit-animation-duration: 18s, 6470ms;
          animation-duration: 18s, 6470ms;
  -webkit-animation-delay: 0ms, 8336ms;
          animation-delay: 0ms, 8336ms;
}

@-webkit-keyframes move7 {
  0% {
    -webkit-transform: translateX(40vw) translateY(47vh) scale(1);
            transform: translateX(40vw) translateY(47vh) scale(1);
  }
  3.5714285714% {
    -webkit-transform: translateX(-21vw) translateY(-17vh) scale(0.78);
            transform: translateX(-21vw) translateY(-17vh) scale(0.78);
  }
  7.1428571429% {
    -webkit-transform: translateX(24vw) translateY(17vh) scale(0.28);
            transform: translateX(24vw) translateY(17vh) scale(0.28);
  }
  10.7142857143% {
    -webkit-transform: translateX(41vw) translateY(23vh) scale(0.96);
            transform: translateX(41vw) translateY(23vh) scale(0.96);
  }
  14.2857142857% {
    -webkit-transform: translateX(14vw) translateY(13vh) scale(0.95);
            transform: translateX(14vw) translateY(13vh) scale(0.95);
  }
  17.8571428571% {
    -webkit-transform: translateX(-17vw) translateY(49vh) scale(0.69);
            transform: translateX(-17vw) translateY(49vh) scale(0.69);
  }
  21.4285714286% {
    -webkit-transform: translateX(9vw) translateY(20vh) scale(0.29);
            transform: translateX(9vw) translateY(20vh) scale(0.29);
  }
  25% {
    -webkit-transform: translateX(-11vw) translateY(30vh) scale(0.96);
            transform: translateX(-11vw) translateY(30vh) scale(0.96);
  }
  28.5714285714% {
    -webkit-transform: translateX(32vw) translateY(-27vh) scale(0.43);
            transform: translateX(32vw) translateY(-27vh) scale(0.43);
  }
  32.1428571429% {
    -webkit-transform: translateX(-48vw) translateY(-7vh) scale(0.9);
            transform: translateX(-48vw) translateY(-7vh) scale(0.9);
  }
  35.7142857143% {
    -webkit-transform: translateX(-19vw) translateY(49vh) scale(0.41);
            transform: translateX(-19vw) translateY(49vh) scale(0.41);
  }
  39.2857142857% {
    -webkit-transform: translateX(7vw) translateY(-36vh) scale(0.5);
            transform: translateX(7vw) translateY(-36vh) scale(0.5);
  }
  42.8571428571% {
    -webkit-transform: translateX(-3vw) translateY(-40vh) scale(0.78);
            transform: translateX(-3vw) translateY(-40vh) scale(0.78);
  }
  46.4285714286% {
    -webkit-transform: translateX(47vw) translateY(6vh) scale(0.26);
            transform: translateX(47vw) translateY(6vh) scale(0.26);
  }
  50% {
    -webkit-transform: translateX(-33vw) translateY(-38vh) scale(0.3);
            transform: translateX(-33vw) translateY(-38vh) scale(0.3);
  }
  53.5714285714% {
    -webkit-transform: translateX(-38vw) translateY(-10vh) scale(0.71);
            transform: translateX(-38vw) translateY(-10vh) scale(0.71);
  }
  57.1428571429% {
    -webkit-transform: translateX(36vw) translateY(-4vh) scale(0.96);
            transform: translateX(36vw) translateY(-4vh) scale(0.96);
  }
  60.7142857143% {
    -webkit-transform: translateX(16vw) translateY(6vh) scale(0.62);
            transform: translateX(16vw) translateY(6vh) scale(0.62);
  }
  64.2857142857% {
    -webkit-transform: translateX(-30vw) translateY(-39vh) scale(0.52);
            transform: translateX(-30vw) translateY(-39vh) scale(0.52);
  }
  67.8571428571% {
    -webkit-transform: translateX(-37vw) translateY(33vh) scale(0.45);
            transform: translateX(-37vw) translateY(33vh) scale(0.45);
  }
  71.4285714286% {
    -webkit-transform: translateX(-10vw) translateY(-28vh) scale(0.78);
            transform: translateX(-10vw) translateY(-28vh) scale(0.78);
  }
  75% {
    -webkit-transform: translateX(48vw) translateY(16vh) scale(0.71);
            transform: translateX(48vw) translateY(16vh) scale(0.71);
  }
  78.5714285714% {
    -webkit-transform: translateX(-45vw) translateY(-39vh) scale(0.63);
            transform: translateX(-45vw) translateY(-39vh) scale(0.63);
  }
  82.1428571429% {
    -webkit-transform: translateX(22vw) translateY(-49vh) scale(0.37);
            transform: translateX(22vw) translateY(-49vh) scale(0.37);
  }
  85.7142857143% {
    -webkit-transform: translateX(49vw) translateY(27vh) scale(0.26);
            transform: translateX(49vw) translateY(27vh) scale(0.26);
  }
  89.2857142857% {
    -webkit-transform: translateX(23vw) translateY(-44vh) scale(0.73);
            transform: translateX(23vw) translateY(-44vh) scale(0.73);
  }
  92.8571428571% {
    -webkit-transform: translateX(-2vw) translateY(29vh) scale(0.47);
            transform: translateX(-2vw) translateY(29vh) scale(0.47);
  }
  96.4285714286% {
    -webkit-transform: translateX(-2vw) translateY(-30vh) scale(0.57);
            transform: translateX(-2vw) translateY(-30vh) scale(0.57);
  }
  100% {
    -webkit-transform: translateX(-10vw) translateY(-29vh) scale(0.96);
            transform: translateX(-10vw) translateY(-29vh) scale(0.96);
  }
}

@keyframes move7 {
  0% {
    -webkit-transform: translateX(40vw) translateY(47vh) scale(1);
            transform: translateX(40vw) translateY(47vh) scale(1);
  }
  3.5714285714% {
    -webkit-transform: translateX(-21vw) translateY(-17vh) scale(0.78);
            transform: translateX(-21vw) translateY(-17vh) scale(0.78);
  }
  7.1428571429% {
    -webkit-transform: translateX(24vw) translateY(17vh) scale(0.28);
            transform: translateX(24vw) translateY(17vh) scale(0.28);
  }
  10.7142857143% {
    -webkit-transform: translateX(41vw) translateY(23vh) scale(0.96);
            transform: translateX(41vw) translateY(23vh) scale(0.96);
  }
  14.2857142857% {
    -webkit-transform: translateX(14vw) translateY(13vh) scale(0.95);
            transform: translateX(14vw) translateY(13vh) scale(0.95);
  }
  17.8571428571% {
    -webkit-transform: translateX(-17vw) translateY(49vh) scale(0.69);
            transform: translateX(-17vw) translateY(49vh) scale(0.69);
  }
  21.4285714286% {
    -webkit-transform: translateX(9vw) translateY(20vh) scale(0.29);
            transform: translateX(9vw) translateY(20vh) scale(0.29);
  }
  25% {
    -webkit-transform: translateX(-11vw) translateY(30vh) scale(0.96);
            transform: translateX(-11vw) translateY(30vh) scale(0.96);
  }
  28.5714285714% {
    -webkit-transform: translateX(32vw) translateY(-27vh) scale(0.43);
            transform: translateX(32vw) translateY(-27vh) scale(0.43);
  }
  32.1428571429% {
    -webkit-transform: translateX(-48vw) translateY(-7vh) scale(0.9);
            transform: translateX(-48vw) translateY(-7vh) scale(0.9);
  }
  35.7142857143% {
    -webkit-transform: translateX(-19vw) translateY(49vh) scale(0.41);
            transform: translateX(-19vw) translateY(49vh) scale(0.41);
  }
  39.2857142857% {
    -webkit-transform: translateX(7vw) translateY(-36vh) scale(0.5);
            transform: translateX(7vw) translateY(-36vh) scale(0.5);
  }
  42.8571428571% {
    -webkit-transform: translateX(-3vw) translateY(-40vh) scale(0.78);
            transform: translateX(-3vw) translateY(-40vh) scale(0.78);
  }
  46.4285714286% {
    -webkit-transform: translateX(47vw) translateY(6vh) scale(0.26);
            transform: translateX(47vw) translateY(6vh) scale(0.26);
  }
  50% {
    -webkit-transform: translateX(-33vw) translateY(-38vh) scale(0.3);
            transform: translateX(-33vw) translateY(-38vh) scale(0.3);
  }
  53.5714285714% {
    -webkit-transform: translateX(-38vw) translateY(-10vh) scale(0.71);
            transform: translateX(-38vw) translateY(-10vh) scale(0.71);
  }
  57.1428571429% {
    -webkit-transform: translateX(36vw) translateY(-4vh) scale(0.96);
            transform: translateX(36vw) translateY(-4vh) scale(0.96);
  }
  60.7142857143% {
    -webkit-transform: translateX(16vw) translateY(6vh) scale(0.62);
            transform: translateX(16vw) translateY(6vh) scale(0.62);
  }
  64.2857142857% {
    -webkit-transform: translateX(-30vw) translateY(-39vh) scale(0.52);
            transform: translateX(-30vw) translateY(-39vh) scale(0.52);
  }
  67.8571428571% {
    -webkit-transform: translateX(-37vw) translateY(33vh) scale(0.45);
            transform: translateX(-37vw) translateY(33vh) scale(0.45);
  }
  71.4285714286% {
    -webkit-transform: translateX(-10vw) translateY(-28vh) scale(0.78);
            transform: translateX(-10vw) translateY(-28vh) scale(0.78);
  }
  75% {
    -webkit-transform: translateX(48vw) translateY(16vh) scale(0.71);
            transform: translateX(48vw) translateY(16vh) scale(0.71);
  }
  78.5714285714% {
    -webkit-transform: translateX(-45vw) translateY(-39vh) scale(0.63);
            transform: translateX(-45vw) translateY(-39vh) scale(0.63);
  }
  82.1428571429% {
    -webkit-transform: translateX(22vw) translateY(-49vh) scale(0.37);
            transform: translateX(22vw) translateY(-49vh) scale(0.37);
  }
  85.7142857143% {
    -webkit-transform: translateX(49vw) translateY(27vh) scale(0.26);
            transform: translateX(49vw) translateY(27vh) scale(0.26);
  }
  89.2857142857% {
    -webkit-transform: translateX(23vw) translateY(-44vh) scale(0.73);
            transform: translateX(23vw) translateY(-44vh) scale(0.73);
  }
  92.8571428571% {
    -webkit-transform: translateX(-2vw) translateY(29vh) scale(0.47);
            transform: translateX(-2vw) translateY(29vh) scale(0.47);
  }
  96.4285714286% {
    -webkit-transform: translateX(-2vw) translateY(-30vh) scale(0.57);
            transform: translateX(-2vw) translateY(-30vh) scale(0.57);
  }
  100% {
    -webkit-transform: translateX(-10vw) translateY(-29vh) scale(0.96);
            transform: translateX(-10vw) translateY(-29vh) scale(0.96);
  }
}
.firefly:nth-child(8) {
  -webkit-animation-name: move8;
          animation-name: move8;
}
.firefly:nth-child(8)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.firefly:nth-child(8)::after {
  -webkit-animation-duration: 15s, 6398ms;
          animation-duration: 15s, 6398ms;
  -webkit-animation-delay: 0ms, 565ms;
          animation-delay: 0ms, 565ms;
}

@-webkit-keyframes move8 {
  0% {
    -webkit-transform: translateX(8vw) translateY(-41vh) scale(0.92);
            transform: translateX(8vw) translateY(-41vh) scale(0.92);
  }
  4.5454545455% {
    -webkit-transform: translateX(-26vw) translateY(43vh) scale(0.97);
            transform: translateX(-26vw) translateY(43vh) scale(0.97);
  }
  9.0909090909% {
    -webkit-transform: translateX(38vw) translateY(-44vh) scale(0.43);
            transform: translateX(38vw) translateY(-44vh) scale(0.43);
  }
  13.6363636364% {
    -webkit-transform: translateX(-35vw) translateY(40vh) scale(0.78);
            transform: translateX(-35vw) translateY(40vh) scale(0.78);
  }
  18.1818181818% {
    -webkit-transform: translateX(-7vw) translateY(-35vh) scale(0.68);
            transform: translateX(-7vw) translateY(-35vh) scale(0.68);
  }
  22.7272727273% {
    -webkit-transform: translateX(-43vw) translateY(-29vh) scale(0.48);
            transform: translateX(-43vw) translateY(-29vh) scale(0.48);
  }
  27.2727272727% {
    -webkit-transform: translateX(-18vw) translateY(27vh) scale(0.7);
            transform: translateX(-18vw) translateY(27vh) scale(0.7);
  }
  31.8181818182% {
    -webkit-transform: translateX(42vw) translateY(-45vh) scale(0.77);
            transform: translateX(42vw) translateY(-45vh) scale(0.77);
  }
  36.3636363636% {
    -webkit-transform: translateX(1vw) translateY(-13vh) scale(0.84);
            transform: translateX(1vw) translateY(-13vh) scale(0.84);
  }
  40.9090909091% {
    -webkit-transform: translateX(35vw) translateY(-26vh) scale(0.84);
            transform: translateX(35vw) translateY(-26vh) scale(0.84);
  }
  45.4545454545% {
    -webkit-transform: translateX(-3vw) translateY(48vh) scale(0.29);
            transform: translateX(-3vw) translateY(48vh) scale(0.29);
  }
  50% {
    -webkit-transform: translateX(9vw) translateY(-4vh) scale(0.99);
            transform: translateX(9vw) translateY(-4vh) scale(0.99);
  }
  54.5454545455% {
    -webkit-transform: translateX(-36vw) translateY(-36vh) scale(0.5);
            transform: translateX(-36vw) translateY(-36vh) scale(0.5);
  }
  59.0909090909% {
    -webkit-transform: translateX(42vw) translateY(-44vh) scale(0.39);
            transform: translateX(42vw) translateY(-44vh) scale(0.39);
  }
  63.6363636364% {
    -webkit-transform: translateX(26vw) translateY(20vh) scale(0.76);
            transform: translateX(26vw) translateY(20vh) scale(0.76);
  }
  68.1818181818% {
    -webkit-transform: translateX(30vw) translateY(48vh) scale(0.9);
            transform: translateX(30vw) translateY(48vh) scale(0.9);
  }
  72.7272727273% {
    -webkit-transform: translateX(-9vw) translateY(35vh) scale(0.97);
            transform: translateX(-9vw) translateY(35vh) scale(0.97);
  }
  77.2727272727% {
    -webkit-transform: translateX(-47vw) translateY(-35vh) scale(0.53);
            transform: translateX(-47vw) translateY(-35vh) scale(0.53);
  }
  81.8181818182% {
    -webkit-transform: translateX(-49vw) translateY(-29vh) scale(0.26);
            transform: translateX(-49vw) translateY(-29vh) scale(0.26);
  }
  86.3636363636% {
    -webkit-transform: translateX(-24vw) translateY(36vh) scale(0.36);
            transform: translateX(-24vw) translateY(36vh) scale(0.36);
  }
  90.9090909091% {
    -webkit-transform: translateX(-33vw) translateY(20vh) scale(0.52);
            transform: translateX(-33vw) translateY(20vh) scale(0.52);
  }
  95.4545454545% {
    -webkit-transform: translateX(14vw) translateY(10vh) scale(0.8);
            transform: translateX(14vw) translateY(10vh) scale(0.8);
  }
  100% {
    -webkit-transform: translateX(14vw) translateY(-30vh) scale(0.54);
            transform: translateX(14vw) translateY(-30vh) scale(0.54);
  }
}

@keyframes move8 {
  0% {
    -webkit-transform: translateX(8vw) translateY(-41vh) scale(0.92);
            transform: translateX(8vw) translateY(-41vh) scale(0.92);
  }
  4.5454545455% {
    -webkit-transform: translateX(-26vw) translateY(43vh) scale(0.97);
            transform: translateX(-26vw) translateY(43vh) scale(0.97);
  }
  9.0909090909% {
    -webkit-transform: translateX(38vw) translateY(-44vh) scale(0.43);
            transform: translateX(38vw) translateY(-44vh) scale(0.43);
  }
  13.6363636364% {
    -webkit-transform: translateX(-35vw) translateY(40vh) scale(0.78);
            transform: translateX(-35vw) translateY(40vh) scale(0.78);
  }
  18.1818181818% {
    -webkit-transform: translateX(-7vw) translateY(-35vh) scale(0.68);
            transform: translateX(-7vw) translateY(-35vh) scale(0.68);
  }
  22.7272727273% {
    -webkit-transform: translateX(-43vw) translateY(-29vh) scale(0.48);
            transform: translateX(-43vw) translateY(-29vh) scale(0.48);
  }
  27.2727272727% {
    -webkit-transform: translateX(-18vw) translateY(27vh) scale(0.7);
            transform: translateX(-18vw) translateY(27vh) scale(0.7);
  }
  31.8181818182% {
    -webkit-transform: translateX(42vw) translateY(-45vh) scale(0.77);
            transform: translateX(42vw) translateY(-45vh) scale(0.77);
  }
  36.3636363636% {
    -webkit-transform: translateX(1vw) translateY(-13vh) scale(0.84);
            transform: translateX(1vw) translateY(-13vh) scale(0.84);
  }
  40.9090909091% {
    -webkit-transform: translateX(35vw) translateY(-26vh) scale(0.84);
            transform: translateX(35vw) translateY(-26vh) scale(0.84);
  }
  45.4545454545% {
    -webkit-transform: translateX(-3vw) translateY(48vh) scale(0.29);
            transform: translateX(-3vw) translateY(48vh) scale(0.29);
  }
  50% {
    -webkit-transform: translateX(9vw) translateY(-4vh) scale(0.99);
            transform: translateX(9vw) translateY(-4vh) scale(0.99);
  }
  54.5454545455% {
    -webkit-transform: translateX(-36vw) translateY(-36vh) scale(0.5);
            transform: translateX(-36vw) translateY(-36vh) scale(0.5);
  }
  59.0909090909% {
    -webkit-transform: translateX(42vw) translateY(-44vh) scale(0.39);
            transform: translateX(42vw) translateY(-44vh) scale(0.39);
  }
  63.6363636364% {
    -webkit-transform: translateX(26vw) translateY(20vh) scale(0.76);
            transform: translateX(26vw) translateY(20vh) scale(0.76);
  }
  68.1818181818% {
    -webkit-transform: translateX(30vw) translateY(48vh) scale(0.9);
            transform: translateX(30vw) translateY(48vh) scale(0.9);
  }
  72.7272727273% {
    -webkit-transform: translateX(-9vw) translateY(35vh) scale(0.97);
            transform: translateX(-9vw) translateY(35vh) scale(0.97);
  }
  77.2727272727% {
    -webkit-transform: translateX(-47vw) translateY(-35vh) scale(0.53);
            transform: translateX(-47vw) translateY(-35vh) scale(0.53);
  }
  81.8181818182% {
    -webkit-transform: translateX(-49vw) translateY(-29vh) scale(0.26);
            transform: translateX(-49vw) translateY(-29vh) scale(0.26);
  }
  86.3636363636% {
    -webkit-transform: translateX(-24vw) translateY(36vh) scale(0.36);
            transform: translateX(-24vw) translateY(36vh) scale(0.36);
  }
  90.9090909091% {
    -webkit-transform: translateX(-33vw) translateY(20vh) scale(0.52);
            transform: translateX(-33vw) translateY(20vh) scale(0.52);
  }
  95.4545454545% {
    -webkit-transform: translateX(14vw) translateY(10vh) scale(0.8);
            transform: translateX(14vw) translateY(10vh) scale(0.8);
  }
  100% {
    -webkit-transform: translateX(14vw) translateY(-30vh) scale(0.54);
            transform: translateX(14vw) translateY(-30vh) scale(0.54);
  }
}
.firefly:nth-child(9) {
  -webkit-animation-name: move9;
          animation-name: move9;
}
.firefly:nth-child(9)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.firefly:nth-child(9)::after {
  -webkit-animation-duration: 15s, 5044ms;
          animation-duration: 15s, 5044ms;
  -webkit-animation-delay: 0ms, 1556ms;
          animation-delay: 0ms, 1556ms;
}

@-webkit-keyframes move9 {
  0% {
    -webkit-transform: translateX(27vw) translateY(-37vh) scale(0.9);
            transform: translateX(27vw) translateY(-37vh) scale(0.9);
  }
  4.1666666667% {
    -webkit-transform: translateX(12vw) translateY(-22vh) scale(0.44);
            transform: translateX(12vw) translateY(-22vh) scale(0.44);
  }
  8.3333333333% {
    -webkit-transform: translateX(-13vw) translateY(-28vh) scale(0.53);
            transform: translateX(-13vw) translateY(-28vh) scale(0.53);
  }
  12.5% {
    -webkit-transform: translateX(-43vw) translateY(18vh) scale(0.39);
            transform: translateX(-43vw) translateY(18vh) scale(0.39);
  }
  16.6666666667% {
    -webkit-transform: translateX(34vw) translateY(31vh) scale(0.97);
            transform: translateX(34vw) translateY(31vh) scale(0.97);
  }
  20.8333333333% {
    -webkit-transform: translateX(47vw) translateY(-33vh) scale(0.97);
            transform: translateX(47vw) translateY(-33vh) scale(0.97);
  }
  25% {
    -webkit-transform: translateX(35vw) translateY(-47vh) scale(0.42);
            transform: translateX(35vw) translateY(-47vh) scale(0.42);
  }
  29.1666666667% {
    -webkit-transform: translateX(-47vw) translateY(39vh) scale(0.4);
            transform: translateX(-47vw) translateY(39vh) scale(0.4);
  }
  33.3333333333% {
    -webkit-transform: translateX(40vw) translateY(-45vh) scale(0.65);
            transform: translateX(40vw) translateY(-45vh) scale(0.65);
  }
  37.5% {
    -webkit-transform: translateX(43vw) translateY(-23vh) scale(0.6);
            transform: translateX(43vw) translateY(-23vh) scale(0.6);
  }
  41.6666666667% {
    -webkit-transform: translateX(38vw) translateY(15vh) scale(0.63);
            transform: translateX(38vw) translateY(15vh) scale(0.63);
  }
  45.8333333333% {
    -webkit-transform: translateX(49vw) translateY(34vh) scale(0.82);
            transform: translateX(49vw) translateY(34vh) scale(0.82);
  }
  50% {
    -webkit-transform: translateX(-30vw) translateY(-9vh) scale(0.91);
            transform: translateX(-30vw) translateY(-9vh) scale(0.91);
  }
  54.1666666667% {
    -webkit-transform: translateX(18vw) translateY(-31vh) scale(0.73);
            transform: translateX(18vw) translateY(-31vh) scale(0.73);
  }
  58.3333333333% {
    -webkit-transform: translateX(32vw) translateY(-11vh) scale(0.97);
            transform: translateX(32vw) translateY(-11vh) scale(0.97);
  }
  62.5% {
    -webkit-transform: translateX(-12vw) translateY(31vh) scale(0.77);
            transform: translateX(-12vw) translateY(31vh) scale(0.77);
  }
  66.6666666667% {
    -webkit-transform: translateX(26vw) translateY(12vh) scale(0.84);
            transform: translateX(26vw) translateY(12vh) scale(0.84);
  }
  70.8333333333% {
    -webkit-transform: translateX(2vw) translateY(-46vh) scale(0.38);
            transform: translateX(2vw) translateY(-46vh) scale(0.38);
  }
  75% {
    -webkit-transform: translateX(46vw) translateY(35vh) scale(0.79);
            transform: translateX(46vw) translateY(35vh) scale(0.79);
  }
  79.1666666667% {
    -webkit-transform: translateX(50vw) translateY(26vh) scale(0.46);
            transform: translateX(50vw) translateY(26vh) scale(0.46);
  }
  83.3333333333% {
    -webkit-transform: translateX(-33vw) translateY(-9vh) scale(0.48);
            transform: translateX(-33vw) translateY(-9vh) scale(0.48);
  }
  87.5% {
    -webkit-transform: translateX(6vw) translateY(-18vh) scale(0.78);
            transform: translateX(6vw) translateY(-18vh) scale(0.78);
  }
  91.6666666667% {
    -webkit-transform: translateX(33vw) translateY(-31vh) scale(0.75);
            transform: translateX(33vw) translateY(-31vh) scale(0.75);
  }
  95.8333333333% {
    -webkit-transform: translateX(27vw) translateY(-13vh) scale(0.52);
            transform: translateX(27vw) translateY(-13vh) scale(0.52);
  }
  100% {
    -webkit-transform: translateX(4vw) translateY(11vh) scale(0.86);
            transform: translateX(4vw) translateY(11vh) scale(0.86);
  }
}

@keyframes move9 {
  0% {
    -webkit-transform: translateX(27vw) translateY(-37vh) scale(0.9);
            transform: translateX(27vw) translateY(-37vh) scale(0.9);
  }
  4.1666666667% {
    -webkit-transform: translateX(12vw) translateY(-22vh) scale(0.44);
            transform: translateX(12vw) translateY(-22vh) scale(0.44);
  }
  8.3333333333% {
    -webkit-transform: translateX(-13vw) translateY(-28vh) scale(0.53);
            transform: translateX(-13vw) translateY(-28vh) scale(0.53);
  }
  12.5% {
    -webkit-transform: translateX(-43vw) translateY(18vh) scale(0.39);
            transform: translateX(-43vw) translateY(18vh) scale(0.39);
  }
  16.6666666667% {
    -webkit-transform: translateX(34vw) translateY(31vh) scale(0.97);
            transform: translateX(34vw) translateY(31vh) scale(0.97);
  }
  20.8333333333% {
    -webkit-transform: translateX(47vw) translateY(-33vh) scale(0.97);
            transform: translateX(47vw) translateY(-33vh) scale(0.97);
  }
  25% {
    -webkit-transform: translateX(35vw) translateY(-47vh) scale(0.42);
            transform: translateX(35vw) translateY(-47vh) scale(0.42);
  }
  29.1666666667% {
    -webkit-transform: translateX(-47vw) translateY(39vh) scale(0.4);
            transform: translateX(-47vw) translateY(39vh) scale(0.4);
  }
  33.3333333333% {
    -webkit-transform: translateX(40vw) translateY(-45vh) scale(0.65);
            transform: translateX(40vw) translateY(-45vh) scale(0.65);
  }
  37.5% {
    -webkit-transform: translateX(43vw) translateY(-23vh) scale(0.6);
            transform: translateX(43vw) translateY(-23vh) scale(0.6);
  }
  41.6666666667% {
    -webkit-transform: translateX(38vw) translateY(15vh) scale(0.63);
            transform: translateX(38vw) translateY(15vh) scale(0.63);
  }
  45.8333333333% {
    -webkit-transform: translateX(49vw) translateY(34vh) scale(0.82);
            transform: translateX(49vw) translateY(34vh) scale(0.82);
  }
  50% {
    -webkit-transform: translateX(-30vw) translateY(-9vh) scale(0.91);
            transform: translateX(-30vw) translateY(-9vh) scale(0.91);
  }
  54.1666666667% {
    -webkit-transform: translateX(18vw) translateY(-31vh) scale(0.73);
            transform: translateX(18vw) translateY(-31vh) scale(0.73);
  }
  58.3333333333% {
    -webkit-transform: translateX(32vw) translateY(-11vh) scale(0.97);
            transform: translateX(32vw) translateY(-11vh) scale(0.97);
  }
  62.5% {
    -webkit-transform: translateX(-12vw) translateY(31vh) scale(0.77);
            transform: translateX(-12vw) translateY(31vh) scale(0.77);
  }
  66.6666666667% {
    -webkit-transform: translateX(26vw) translateY(12vh) scale(0.84);
            transform: translateX(26vw) translateY(12vh) scale(0.84);
  }
  70.8333333333% {
    -webkit-transform: translateX(2vw) translateY(-46vh) scale(0.38);
            transform: translateX(2vw) translateY(-46vh) scale(0.38);
  }
  75% {
    -webkit-transform: translateX(46vw) translateY(35vh) scale(0.79);
            transform: translateX(46vw) translateY(35vh) scale(0.79);
  }
  79.1666666667% {
    -webkit-transform: translateX(50vw) translateY(26vh) scale(0.46);
            transform: translateX(50vw) translateY(26vh) scale(0.46);
  }
  83.3333333333% {
    -webkit-transform: translateX(-33vw) translateY(-9vh) scale(0.48);
            transform: translateX(-33vw) translateY(-9vh) scale(0.48);
  }
  87.5% {
    -webkit-transform: translateX(6vw) translateY(-18vh) scale(0.78);
            transform: translateX(6vw) translateY(-18vh) scale(0.78);
  }
  91.6666666667% {
    -webkit-transform: translateX(33vw) translateY(-31vh) scale(0.75);
            transform: translateX(33vw) translateY(-31vh) scale(0.75);
  }
  95.8333333333% {
    -webkit-transform: translateX(27vw) translateY(-13vh) scale(0.52);
            transform: translateX(27vw) translateY(-13vh) scale(0.52);
  }
  100% {
    -webkit-transform: translateX(4vw) translateY(11vh) scale(0.86);
            transform: translateX(4vw) translateY(11vh) scale(0.86);
  }
}
.firefly:nth-child(10) {
  -webkit-animation-name: move10;
          animation-name: move10;
}
.firefly:nth-child(10)::before {
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
}
.firefly:nth-child(10)::after {
  -webkit-animation-duration: 15s, 5550ms;
          animation-duration: 15s, 5550ms;
  -webkit-animation-delay: 0ms, 4125ms;
          animation-delay: 0ms, 4125ms;
}

@-webkit-keyframes move10 {
  0% {
    -webkit-transform: translateX(-26vw) translateY(-38vh) scale(0.49);
            transform: translateX(-26vw) translateY(-38vh) scale(0.49);
  }
  5.5555555556% {
    -webkit-transform: translateX(21vw) translateY(7vh) scale(0.8);
            transform: translateX(21vw) translateY(7vh) scale(0.8);
  }
  11.1111111111% {
    -webkit-transform: translateX(-43vw) translateY(-2vh) scale(0.75);
            transform: translateX(-43vw) translateY(-2vh) scale(0.75);
  }
  16.6666666667% {
    -webkit-transform: translateX(29vw) translateY(-17vh) scale(1);
            transform: translateX(29vw) translateY(-17vh) scale(1);
  }
  22.2222222222% {
    -webkit-transform: translateX(-43vw) translateY(13vh) scale(0.88);
            transform: translateX(-43vw) translateY(13vh) scale(0.88);
  }
  27.7777777778% {
    -webkit-transform: translateX(33vw) translateY(-48vh) scale(0.86);
            transform: translateX(33vw) translateY(-48vh) scale(0.86);
  }
  33.3333333333% {
    -webkit-transform: translateX(17vw) translateY(-23vh) scale(0.95);
            transform: translateX(17vw) translateY(-23vh) scale(0.95);
  }
  38.8888888889% {
    -webkit-transform: translateX(-7vw) translateY(39vh) scale(0.4);
            transform: translateX(-7vw) translateY(39vh) scale(0.4);
  }
  44.4444444444% {
    -webkit-transform: translateX(-44vw) translateY(-35vh) scale(0.39);
            transform: translateX(-44vw) translateY(-35vh) scale(0.39);
  }
  50% {
    -webkit-transform: translateX(-23vw) translateY(-10vh) scale(0.54);
            transform: translateX(-23vw) translateY(-10vh) scale(0.54);
  }
  55.5555555556% {
    -webkit-transform: translateX(8vw) translateY(39vh) scale(0.63);
            transform: translateX(8vw) translateY(39vh) scale(0.63);
  }
  61.1111111111% {
    -webkit-transform: translateX(-3vw) translateY(28vh) scale(0.46);
            transform: translateX(-3vw) translateY(28vh) scale(0.46);
  }
  66.6666666667% {
    -webkit-transform: translateX(19vw) translateY(-35vh) scale(0.55);
            transform: translateX(19vw) translateY(-35vh) scale(0.55);
  }
  72.2222222222% {
    -webkit-transform: translateX(-32vw) translateY(1vh) scale(0.87);
            transform: translateX(-32vw) translateY(1vh) scale(0.87);
  }
  77.7777777778% {
    -webkit-transform: translateX(-18vw) translateY(11vh) scale(0.62);
            transform: translateX(-18vw) translateY(11vh) scale(0.62);
  }
  83.3333333333% {
    -webkit-transform: translateX(-24vw) translateY(-31vh) scale(0.42);
            transform: translateX(-24vw) translateY(-31vh) scale(0.42);
  }
  88.8888888889% {
    -webkit-transform: translateX(5vw) translateY(34vh) scale(0.5);
            transform: translateX(5vw) translateY(34vh) scale(0.5);
  }
  94.4444444444% {
    -webkit-transform: translateX(-11vw) translateY(32vh) scale(0.36);
            transform: translateX(-11vw) translateY(32vh) scale(0.36);
  }
  100% {
    -webkit-transform: translateX(5vw) translateY(-8vh) scale(0.99);
            transform: translateX(5vw) translateY(-8vh) scale(0.99);
  }
}

@keyframes move10 {
  0% {
    -webkit-transform: translateX(-26vw) translateY(-38vh) scale(0.49);
            transform: translateX(-26vw) translateY(-38vh) scale(0.49);
  }
  5.5555555556% {
    -webkit-transform: translateX(21vw) translateY(7vh) scale(0.8);
            transform: translateX(21vw) translateY(7vh) scale(0.8);
  }
  11.1111111111% {
    -webkit-transform: translateX(-43vw) translateY(-2vh) scale(0.75);
            transform: translateX(-43vw) translateY(-2vh) scale(0.75);
  }
  16.6666666667% {
    -webkit-transform: translateX(29vw) translateY(-17vh) scale(1);
            transform: translateX(29vw) translateY(-17vh) scale(1);
  }
  22.2222222222% {
    -webkit-transform: translateX(-43vw) translateY(13vh) scale(0.88);
            transform: translateX(-43vw) translateY(13vh) scale(0.88);
  }
  27.7777777778% {
    -webkit-transform: translateX(33vw) translateY(-48vh) scale(0.86);
            transform: translateX(33vw) translateY(-48vh) scale(0.86);
  }
  33.3333333333% {
    -webkit-transform: translateX(17vw) translateY(-23vh) scale(0.95);
            transform: translateX(17vw) translateY(-23vh) scale(0.95);
  }
  38.8888888889% {
    -webkit-transform: translateX(-7vw) translateY(39vh) scale(0.4);
            transform: translateX(-7vw) translateY(39vh) scale(0.4);
  }
  44.4444444444% {
    -webkit-transform: translateX(-44vw) translateY(-35vh) scale(0.39);
            transform: translateX(-44vw) translateY(-35vh) scale(0.39);
  }
  50% {
    -webkit-transform: translateX(-23vw) translateY(-10vh) scale(0.54);
            transform: translateX(-23vw) translateY(-10vh) scale(0.54);
  }
  55.5555555556% {
    -webkit-transform: translateX(8vw) translateY(39vh) scale(0.63);
            transform: translateX(8vw) translateY(39vh) scale(0.63);
  }
  61.1111111111% {
    -webkit-transform: translateX(-3vw) translateY(28vh) scale(0.46);
            transform: translateX(-3vw) translateY(28vh) scale(0.46);
  }
  66.6666666667% {
    -webkit-transform: translateX(19vw) translateY(-35vh) scale(0.55);
            transform: translateX(19vw) translateY(-35vh) scale(0.55);
  }
  72.2222222222% {
    -webkit-transform: translateX(-32vw) translateY(1vh) scale(0.87);
            transform: translateX(-32vw) translateY(1vh) scale(0.87);
  }
  77.7777777778% {
    -webkit-transform: translateX(-18vw) translateY(11vh) scale(0.62);
            transform: translateX(-18vw) translateY(11vh) scale(0.62);
  }
  83.3333333333% {
    -webkit-transform: translateX(-24vw) translateY(-31vh) scale(0.42);
            transform: translateX(-24vw) translateY(-31vh) scale(0.42);
  }
  88.8888888889% {
    -webkit-transform: translateX(5vw) translateY(34vh) scale(0.5);
            transform: translateX(5vw) translateY(34vh) scale(0.5);
  }
  94.4444444444% {
    -webkit-transform: translateX(-11vw) translateY(32vh) scale(0.36);
            transform: translateX(-11vw) translateY(32vh) scale(0.36);
  }
  100% {
    -webkit-transform: translateX(5vw) translateY(-8vh) scale(0.99);
            transform: translateX(5vw) translateY(-8vh) scale(0.99);
  }
}
.firefly:nth-child(11) {
  -webkit-animation-name: move11;
          animation-name: move11;
}
.firefly:nth-child(11)::before {
  -webkit-animation-duration: 16s;
          animation-duration: 16s;
}
.firefly:nth-child(11)::after {
  -webkit-animation-duration: 16s, 10297ms;
          animation-duration: 16s, 10297ms;
  -webkit-animation-delay: 0ms, 4707ms;
          animation-delay: 0ms, 4707ms;
}

@-webkit-keyframes move11 {
  0% {
    -webkit-transform: translateX(-36vw) translateY(-2vh) scale(0.37);
            transform: translateX(-36vw) translateY(-2vh) scale(0.37);
  }
  4.7619047619% {
    -webkit-transform: translateX(-27vw) translateY(30vh) scale(0.38);
            transform: translateX(-27vw) translateY(30vh) scale(0.38);
  }
  9.5238095238% {
    -webkit-transform: translateX(45vw) translateY(-23vh) scale(0.33);
            transform: translateX(45vw) translateY(-23vh) scale(0.33);
  }
  14.2857142857% {
    -webkit-transform: translateX(25vw) translateY(9vh) scale(0.7);
            transform: translateX(25vw) translateY(9vh) scale(0.7);
  }
  19.0476190476% {
    -webkit-transform: translateX(24vw) translateY(-13vh) scale(0.48);
            transform: translateX(24vw) translateY(-13vh) scale(0.48);
  }
  23.8095238095% {
    -webkit-transform: translateX(43vw) translateY(-5vh) scale(0.68);
            transform: translateX(43vw) translateY(-5vh) scale(0.68);
  }
  28.5714285714% {
    -webkit-transform: translateX(41vw) translateY(2vh) scale(0.83);
            transform: translateX(41vw) translateY(2vh) scale(0.83);
  }
  33.3333333333% {
    -webkit-transform: translateX(-46vw) translateY(-13vh) scale(0.7);
            transform: translateX(-46vw) translateY(-13vh) scale(0.7);
  }
  38.0952380952% {
    -webkit-transform: translateX(-39vw) translateY(-25vh) scale(0.85);
            transform: translateX(-39vw) translateY(-25vh) scale(0.85);
  }
  42.8571428571% {
    -webkit-transform: translateX(-27vw) translateY(23vh) scale(0.33);
            transform: translateX(-27vw) translateY(23vh) scale(0.33);
  }
  47.619047619% {
    -webkit-transform: translateX(-13vw) translateY(45vh) scale(0.3);
            transform: translateX(-13vw) translateY(45vh) scale(0.3);
  }
  52.380952381% {
    -webkit-transform: translateX(10vw) translateY(-28vh) scale(0.55);
            transform: translateX(10vw) translateY(-28vh) scale(0.55);
  }
  57.1428571429% {
    -webkit-transform: translateX(-30vw) translateY(-16vh) scale(0.26);
            transform: translateX(-30vw) translateY(-16vh) scale(0.26);
  }
  61.9047619048% {
    -webkit-transform: translateX(40vw) translateY(30vh) scale(0.32);
            transform: translateX(40vw) translateY(30vh) scale(0.32);
  }
  66.6666666667% {
    -webkit-transform: translateX(45vw) translateY(37vh) scale(0.79);
            transform: translateX(45vw) translateY(37vh) scale(0.79);
  }
  71.4285714286% {
    -webkit-transform: translateX(-13vw) translateY(-24vh) scale(0.62);
            transform: translateX(-13vw) translateY(-24vh) scale(0.62);
  }
  76.1904761905% {
    -webkit-transform: translateX(33vw) translateY(-12vh) scale(0.94);
            transform: translateX(33vw) translateY(-12vh) scale(0.94);
  }
  80.9523809524% {
    -webkit-transform: translateX(10vw) translateY(-49vh) scale(0.3);
            transform: translateX(10vw) translateY(-49vh) scale(0.3);
  }
  85.7142857143% {
    -webkit-transform: translateX(-15vw) translateY(48vh) scale(0.48);
            transform: translateX(-15vw) translateY(48vh) scale(0.48);
  }
  90.4761904762% {
    -webkit-transform: translateX(-42vw) translateY(40vh) scale(0.54);
            transform: translateX(-42vw) translateY(40vh) scale(0.54);
  }
  95.2380952381% {
    -webkit-transform: translateX(-22vw) translateY(0vh) scale(0.63);
            transform: translateX(-22vw) translateY(0vh) scale(0.63);
  }
  100% {
    -webkit-transform: translateX(32vw) translateY(14vh) scale(0.79);
            transform: translateX(32vw) translateY(14vh) scale(0.79);
  }
}

@keyframes move11 {
  0% {
    -webkit-transform: translateX(-36vw) translateY(-2vh) scale(0.37);
            transform: translateX(-36vw) translateY(-2vh) scale(0.37);
  }
  4.7619047619% {
    -webkit-transform: translateX(-27vw) translateY(30vh) scale(0.38);
            transform: translateX(-27vw) translateY(30vh) scale(0.38);
  }
  9.5238095238% {
    -webkit-transform: translateX(45vw) translateY(-23vh) scale(0.33);
            transform: translateX(45vw) translateY(-23vh) scale(0.33);
  }
  14.2857142857% {
    -webkit-transform: translateX(25vw) translateY(9vh) scale(0.7);
            transform: translateX(25vw) translateY(9vh) scale(0.7);
  }
  19.0476190476% {
    -webkit-transform: translateX(24vw) translateY(-13vh) scale(0.48);
            transform: translateX(24vw) translateY(-13vh) scale(0.48);
  }
  23.8095238095% {
    -webkit-transform: translateX(43vw) translateY(-5vh) scale(0.68);
            transform: translateX(43vw) translateY(-5vh) scale(0.68);
  }
  28.5714285714% {
    -webkit-transform: translateX(41vw) translateY(2vh) scale(0.83);
            transform: translateX(41vw) translateY(2vh) scale(0.83);
  }
  33.3333333333% {
    -webkit-transform: translateX(-46vw) translateY(-13vh) scale(0.7);
            transform: translateX(-46vw) translateY(-13vh) scale(0.7);
  }
  38.0952380952% {
    -webkit-transform: translateX(-39vw) translateY(-25vh) scale(0.85);
            transform: translateX(-39vw) translateY(-25vh) scale(0.85);
  }
  42.8571428571% {
    -webkit-transform: translateX(-27vw) translateY(23vh) scale(0.33);
            transform: translateX(-27vw) translateY(23vh) scale(0.33);
  }
  47.619047619% {
    -webkit-transform: translateX(-13vw) translateY(45vh) scale(0.3);
            transform: translateX(-13vw) translateY(45vh) scale(0.3);
  }
  52.380952381% {
    -webkit-transform: translateX(10vw) translateY(-28vh) scale(0.55);
            transform: translateX(10vw) translateY(-28vh) scale(0.55);
  }
  57.1428571429% {
    -webkit-transform: translateX(-30vw) translateY(-16vh) scale(0.26);
            transform: translateX(-30vw) translateY(-16vh) scale(0.26);
  }
  61.9047619048% {
    -webkit-transform: translateX(40vw) translateY(30vh) scale(0.32);
            transform: translateX(40vw) translateY(30vh) scale(0.32);
  }
  66.6666666667% {
    -webkit-transform: translateX(45vw) translateY(37vh) scale(0.79);
            transform: translateX(45vw) translateY(37vh) scale(0.79);
  }
  71.4285714286% {
    -webkit-transform: translateX(-13vw) translateY(-24vh) scale(0.62);
            transform: translateX(-13vw) translateY(-24vh) scale(0.62);
  }
  76.1904761905% {
    -webkit-transform: translateX(33vw) translateY(-12vh) scale(0.94);
            transform: translateX(33vw) translateY(-12vh) scale(0.94);
  }
  80.9523809524% {
    -webkit-transform: translateX(10vw) translateY(-49vh) scale(0.3);
            transform: translateX(10vw) translateY(-49vh) scale(0.3);
  }
  85.7142857143% {
    -webkit-transform: translateX(-15vw) translateY(48vh) scale(0.48);
            transform: translateX(-15vw) translateY(48vh) scale(0.48);
  }
  90.4761904762% {
    -webkit-transform: translateX(-42vw) translateY(40vh) scale(0.54);
            transform: translateX(-42vw) translateY(40vh) scale(0.54);
  }
  95.2380952381% {
    -webkit-transform: translateX(-22vw) translateY(0vh) scale(0.63);
            transform: translateX(-22vw) translateY(0vh) scale(0.63);
  }
  100% {
    -webkit-transform: translateX(32vw) translateY(14vh) scale(0.79);
            transform: translateX(32vw) translateY(14vh) scale(0.79);
  }
}
.firefly:nth-child(12) {
  -webkit-animation-name: move12;
          animation-name: move12;
}
.firefly:nth-child(12)::before {
  -webkit-animation-duration: 9s;
          animation-duration: 9s;
}
.firefly:nth-child(12)::after {
  -webkit-animation-duration: 9s, 8018ms;
          animation-duration: 9s, 8018ms;
  -webkit-animation-delay: 0ms, 4673ms;
          animation-delay: 0ms, 4673ms;
}

@-webkit-keyframes move12 {
  0% {
    -webkit-transform: translateX(-30vw) translateY(47vh) scale(0.57);
            transform: translateX(-30vw) translateY(47vh) scale(0.57);
  }
  3.5714285714% {
    -webkit-transform: translateX(-19vw) translateY(19vh) scale(0.52);
            transform: translateX(-19vw) translateY(19vh) scale(0.52);
  }
  7.1428571429% {
    -webkit-transform: translateX(-14vw) translateY(48vh) scale(0.9);
            transform: translateX(-14vw) translateY(48vh) scale(0.9);
  }
  10.7142857143% {
    -webkit-transform: translateX(-44vw) translateY(13vh) scale(0.38);
            transform: translateX(-44vw) translateY(13vh) scale(0.38);
  }
  14.2857142857% {
    -webkit-transform: translateX(-10vw) translateY(43vh) scale(0.49);
            transform: translateX(-10vw) translateY(43vh) scale(0.49);
  }
  17.8571428571% {
    -webkit-transform: translateX(39vw) translateY(49vh) scale(0.79);
            transform: translateX(39vw) translateY(49vh) scale(0.79);
  }
  21.4285714286% {
    -webkit-transform: translateX(50vw) translateY(-17vh) scale(0.4);
            transform: translateX(50vw) translateY(-17vh) scale(0.4);
  }
  25% {
    -webkit-transform: translateX(-20vw) translateY(22vh) scale(0.48);
            transform: translateX(-20vw) translateY(22vh) scale(0.48);
  }
  28.5714285714% {
    -webkit-transform: translateX(-27vw) translateY(32vh) scale(0.92);
            transform: translateX(-27vw) translateY(32vh) scale(0.92);
  }
  32.1428571429% {
    -webkit-transform: translateX(11vw) translateY(-7vh) scale(0.99);
            transform: translateX(11vw) translateY(-7vh) scale(0.99);
  }
  35.7142857143% {
    -webkit-transform: translateX(-27vw) translateY(46vh) scale(0.34);
            transform: translateX(-27vw) translateY(46vh) scale(0.34);
  }
  39.2857142857% {
    -webkit-transform: translateX(-24vw) translateY(3vh) scale(0.36);
            transform: translateX(-24vw) translateY(3vh) scale(0.36);
  }
  42.8571428571% {
    -webkit-transform: translateX(48vw) translateY(-30vh) scale(0.33);
            transform: translateX(48vw) translateY(-30vh) scale(0.33);
  }
  46.4285714286% {
    -webkit-transform: translateX(-44vw) translateY(-30vh) scale(0.5);
            transform: translateX(-44vw) translateY(-30vh) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(44vw) translateY(-2vh) scale(0.57);
            transform: translateX(44vw) translateY(-2vh) scale(0.57);
  }
  53.5714285714% {
    -webkit-transform: translateX(-47vw) translateY(-18vh) scale(0.39);
            transform: translateX(-47vw) translateY(-18vh) scale(0.39);
  }
  57.1428571429% {
    -webkit-transform: translateX(45vw) translateY(24vh) scale(0.54);
            transform: translateX(45vw) translateY(24vh) scale(0.54);
  }
  60.7142857143% {
    -webkit-transform: translateX(-32vw) translateY(14vh) scale(0.64);
            transform: translateX(-32vw) translateY(14vh) scale(0.64);
  }
  64.2857142857% {
    -webkit-transform: translateX(-24vw) translateY(37vh) scale(0.81);
            transform: translateX(-24vw) translateY(37vh) scale(0.81);
  }
  67.8571428571% {
    -webkit-transform: translateX(-37vw) translateY(28vh) scale(0.67);
            transform: translateX(-37vw) translateY(28vh) scale(0.67);
  }
  71.4285714286% {
    -webkit-transform: translateX(11vw) translateY(-14vh) scale(0.71);
            transform: translateX(11vw) translateY(-14vh) scale(0.71);
  }
  75% {
    -webkit-transform: translateX(-28vw) translateY(-6vh) scale(0.47);
            transform: translateX(-28vw) translateY(-6vh) scale(0.47);
  }
  78.5714285714% {
    -webkit-transform: translateX(15vw) translateY(-15vh) scale(0.79);
            transform: translateX(15vw) translateY(-15vh) scale(0.79);
  }
  82.1428571429% {
    -webkit-transform: translateX(-8vw) translateY(-9vh) scale(0.51);
            transform: translateX(-8vw) translateY(-9vh) scale(0.51);
  }
  85.7142857143% {
    -webkit-transform: translateX(43vw) translateY(47vh) scale(0.52);
            transform: translateX(43vw) translateY(47vh) scale(0.52);
  }
  89.2857142857% {
    -webkit-transform: translateX(35vw) translateY(-38vh) scale(0.62);
            transform: translateX(35vw) translateY(-38vh) scale(0.62);
  }
  92.8571428571% {
    -webkit-transform: translateX(22vw) translateY(-44vh) scale(1);
            transform: translateX(22vw) translateY(-44vh) scale(1);
  }
  96.4285714286% {
    -webkit-transform: translateX(5vw) translateY(31vh) scale(0.33);
            transform: translateX(5vw) translateY(31vh) scale(0.33);
  }
  100% {
    -webkit-transform: translateX(3vw) translateY(39vh) scale(0.63);
            transform: translateX(3vw) translateY(39vh) scale(0.63);
  }
}

@keyframes move12 {
  0% {
    -webkit-transform: translateX(-30vw) translateY(47vh) scale(0.57);
            transform: translateX(-30vw) translateY(47vh) scale(0.57);
  }
  3.5714285714% {
    -webkit-transform: translateX(-19vw) translateY(19vh) scale(0.52);
            transform: translateX(-19vw) translateY(19vh) scale(0.52);
  }
  7.1428571429% {
    -webkit-transform: translateX(-14vw) translateY(48vh) scale(0.9);
            transform: translateX(-14vw) translateY(48vh) scale(0.9);
  }
  10.7142857143% {
    -webkit-transform: translateX(-44vw) translateY(13vh) scale(0.38);
            transform: translateX(-44vw) translateY(13vh) scale(0.38);
  }
  14.2857142857% {
    -webkit-transform: translateX(-10vw) translateY(43vh) scale(0.49);
            transform: translateX(-10vw) translateY(43vh) scale(0.49);
  }
  17.8571428571% {
    -webkit-transform: translateX(39vw) translateY(49vh) scale(0.79);
            transform: translateX(39vw) translateY(49vh) scale(0.79);
  }
  21.4285714286% {
    -webkit-transform: translateX(50vw) translateY(-17vh) scale(0.4);
            transform: translateX(50vw) translateY(-17vh) scale(0.4);
  }
  25% {
    -webkit-transform: translateX(-20vw) translateY(22vh) scale(0.48);
            transform: translateX(-20vw) translateY(22vh) scale(0.48);
  }
  28.5714285714% {
    -webkit-transform: translateX(-27vw) translateY(32vh) scale(0.92);
            transform: translateX(-27vw) translateY(32vh) scale(0.92);
  }
  32.1428571429% {
    -webkit-transform: translateX(11vw) translateY(-7vh) scale(0.99);
            transform: translateX(11vw) translateY(-7vh) scale(0.99);
  }
  35.7142857143% {
    -webkit-transform: translateX(-27vw) translateY(46vh) scale(0.34);
            transform: translateX(-27vw) translateY(46vh) scale(0.34);
  }
  39.2857142857% {
    -webkit-transform: translateX(-24vw) translateY(3vh) scale(0.36);
            transform: translateX(-24vw) translateY(3vh) scale(0.36);
  }
  42.8571428571% {
    -webkit-transform: translateX(48vw) translateY(-30vh) scale(0.33);
            transform: translateX(48vw) translateY(-30vh) scale(0.33);
  }
  46.4285714286% {
    -webkit-transform: translateX(-44vw) translateY(-30vh) scale(0.5);
            transform: translateX(-44vw) translateY(-30vh) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(44vw) translateY(-2vh) scale(0.57);
            transform: translateX(44vw) translateY(-2vh) scale(0.57);
  }
  53.5714285714% {
    -webkit-transform: translateX(-47vw) translateY(-18vh) scale(0.39);
            transform: translateX(-47vw) translateY(-18vh) scale(0.39);
  }
  57.1428571429% {
    -webkit-transform: translateX(45vw) translateY(24vh) scale(0.54);
            transform: translateX(45vw) translateY(24vh) scale(0.54);
  }
  60.7142857143% {
    -webkit-transform: translateX(-32vw) translateY(14vh) scale(0.64);
            transform: translateX(-32vw) translateY(14vh) scale(0.64);
  }
  64.2857142857% {
    -webkit-transform: translateX(-24vw) translateY(37vh) scale(0.81);
            transform: translateX(-24vw) translateY(37vh) scale(0.81);
  }
  67.8571428571% {
    -webkit-transform: translateX(-37vw) translateY(28vh) scale(0.67);
            transform: translateX(-37vw) translateY(28vh) scale(0.67);
  }
  71.4285714286% {
    -webkit-transform: translateX(11vw) translateY(-14vh) scale(0.71);
            transform: translateX(11vw) translateY(-14vh) scale(0.71);
  }
  75% {
    -webkit-transform: translateX(-28vw) translateY(-6vh) scale(0.47);
            transform: translateX(-28vw) translateY(-6vh) scale(0.47);
  }
  78.5714285714% {
    -webkit-transform: translateX(15vw) translateY(-15vh) scale(0.79);
            transform: translateX(15vw) translateY(-15vh) scale(0.79);
  }
  82.1428571429% {
    -webkit-transform: translateX(-8vw) translateY(-9vh) scale(0.51);
            transform: translateX(-8vw) translateY(-9vh) scale(0.51);
  }
  85.7142857143% {
    -webkit-transform: translateX(43vw) translateY(47vh) scale(0.52);
            transform: translateX(43vw) translateY(47vh) scale(0.52);
  }
  89.2857142857% {
    -webkit-transform: translateX(35vw) translateY(-38vh) scale(0.62);
            transform: translateX(35vw) translateY(-38vh) scale(0.62);
  }
  92.8571428571% {
    -webkit-transform: translateX(22vw) translateY(-44vh) scale(1);
            transform: translateX(22vw) translateY(-44vh) scale(1);
  }
  96.4285714286% {
    -webkit-transform: translateX(5vw) translateY(31vh) scale(0.33);
            transform: translateX(5vw) translateY(31vh) scale(0.33);
  }
  100% {
    -webkit-transform: translateX(3vw) translateY(39vh) scale(0.63);
            transform: translateX(3vw) translateY(39vh) scale(0.63);
  }
}
.firefly:nth-child(13) {
  -webkit-animation-name: move13;
          animation-name: move13;
}
.firefly:nth-child(13)::before {
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
}
.firefly:nth-child(13)::after {
  -webkit-animation-duration: 10s, 9679ms;
          animation-duration: 10s, 9679ms;
  -webkit-animation-delay: 0ms, 8051ms;
          animation-delay: 0ms, 8051ms;
}

@-webkit-keyframes move13 {
  0% {
    -webkit-transform: translateX(22vw) translateY(-45vh) scale(0.86);
            transform: translateX(22vw) translateY(-45vh) scale(0.86);
  }
  4.1666666667% {
    -webkit-transform: translateX(20vw) translateY(6vh) scale(0.73);
            transform: translateX(20vw) translateY(6vh) scale(0.73);
  }
  8.3333333333% {
    -webkit-transform: translateX(-36vw) translateY(15vh) scale(0.72);
            transform: translateX(-36vw) translateY(15vh) scale(0.72);
  }
  12.5% {
    -webkit-transform: translateX(25vw) translateY(-1vh) scale(0.38);
            transform: translateX(25vw) translateY(-1vh) scale(0.38);
  }
  16.6666666667% {
    -webkit-transform: translateX(-19vw) translateY(-28vh) scale(0.91);
            transform: translateX(-19vw) translateY(-28vh) scale(0.91);
  }
  20.8333333333% {
    -webkit-transform: translateX(-45vw) translateY(29vh) scale(0.47);
            transform: translateX(-45vw) translateY(29vh) scale(0.47);
  }
  25% {
    -webkit-transform: translateX(0vw) translateY(14vh) scale(0.55);
            transform: translateX(0vw) translateY(14vh) scale(0.55);
  }
  29.1666666667% {
    -webkit-transform: translateX(-41vw) translateY(-28vh) scale(0.39);
            transform: translateX(-41vw) translateY(-28vh) scale(0.39);
  }
  33.3333333333% {
    -webkit-transform: translateX(-11vw) translateY(34vh) scale(0.37);
            transform: translateX(-11vw) translateY(34vh) scale(0.37);
  }
  37.5% {
    -webkit-transform: translateX(-10vw) translateY(38vh) scale(0.75);
            transform: translateX(-10vw) translateY(38vh) scale(0.75);
  }
  41.6666666667% {
    -webkit-transform: translateX(-3vw) translateY(7vh) scale(0.47);
            transform: translateX(-3vw) translateY(7vh) scale(0.47);
  }
  45.8333333333% {
    -webkit-transform: translateX(22vw) translateY(-11vh) scale(0.97);
            transform: translateX(22vw) translateY(-11vh) scale(0.97);
  }
  50% {
    -webkit-transform: translateX(41vw) translateY(20vh) scale(0.37);
            transform: translateX(41vw) translateY(20vh) scale(0.37);
  }
  54.1666666667% {
    -webkit-transform: translateX(23vw) translateY(-14vh) scale(0.41);
            transform: translateX(23vw) translateY(-14vh) scale(0.41);
  }
  58.3333333333% {
    -webkit-transform: translateX(43vw) translateY(-30vh) scale(0.34);
            transform: translateX(43vw) translateY(-30vh) scale(0.34);
  }
  62.5% {
    -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.32);
            transform: translateX(-31vw) translateY(-31vh) scale(0.32);
  }
  66.6666666667% {
    -webkit-transform: translateX(-21vw) translateY(-11vh) scale(0.42);
            transform: translateX(-21vw) translateY(-11vh) scale(0.42);
  }
  70.8333333333% {
    -webkit-transform: translateX(47vw) translateY(11vh) scale(0.29);
            transform: translateX(47vw) translateY(11vh) scale(0.29);
  }
  75% {
    -webkit-transform: translateX(20vw) translateY(-27vh) scale(0.4);
            transform: translateX(20vw) translateY(-27vh) scale(0.4);
  }
  79.1666666667% {
    -webkit-transform: translateX(-47vw) translateY(37vh) scale(0.52);
            transform: translateX(-47vw) translateY(37vh) scale(0.52);
  }
  83.3333333333% {
    -webkit-transform: translateX(0vw) translateY(3vh) scale(0.44);
            transform: translateX(0vw) translateY(3vh) scale(0.44);
  }
  87.5% {
    -webkit-transform: translateX(27vw) translateY(47vh) scale(0.88);
            transform: translateX(27vw) translateY(47vh) scale(0.88);
  }
  91.6666666667% {
    -webkit-transform: translateX(-5vw) translateY(3vh) scale(0.82);
            transform: translateX(-5vw) translateY(3vh) scale(0.82);
  }
  95.8333333333% {
    -webkit-transform: translateX(16vw) translateY(10vh) scale(0.61);
            transform: translateX(16vw) translateY(10vh) scale(0.61);
  }
  100% {
    -webkit-transform: translateX(5vw) translateY(32vh) scale(0.33);
            transform: translateX(5vw) translateY(32vh) scale(0.33);
  }
}

@keyframes move13 {
  0% {
    -webkit-transform: translateX(22vw) translateY(-45vh) scale(0.86);
            transform: translateX(22vw) translateY(-45vh) scale(0.86);
  }
  4.1666666667% {
    -webkit-transform: translateX(20vw) translateY(6vh) scale(0.73);
            transform: translateX(20vw) translateY(6vh) scale(0.73);
  }
  8.3333333333% {
    -webkit-transform: translateX(-36vw) translateY(15vh) scale(0.72);
            transform: translateX(-36vw) translateY(15vh) scale(0.72);
  }
  12.5% {
    -webkit-transform: translateX(25vw) translateY(-1vh) scale(0.38);
            transform: translateX(25vw) translateY(-1vh) scale(0.38);
  }
  16.6666666667% {
    -webkit-transform: translateX(-19vw) translateY(-28vh) scale(0.91);
            transform: translateX(-19vw) translateY(-28vh) scale(0.91);
  }
  20.8333333333% {
    -webkit-transform: translateX(-45vw) translateY(29vh) scale(0.47);
            transform: translateX(-45vw) translateY(29vh) scale(0.47);
  }
  25% {
    -webkit-transform: translateX(0vw) translateY(14vh) scale(0.55);
            transform: translateX(0vw) translateY(14vh) scale(0.55);
  }
  29.1666666667% {
    -webkit-transform: translateX(-41vw) translateY(-28vh) scale(0.39);
            transform: translateX(-41vw) translateY(-28vh) scale(0.39);
  }
  33.3333333333% {
    -webkit-transform: translateX(-11vw) translateY(34vh) scale(0.37);
            transform: translateX(-11vw) translateY(34vh) scale(0.37);
  }
  37.5% {
    -webkit-transform: translateX(-10vw) translateY(38vh) scale(0.75);
            transform: translateX(-10vw) translateY(38vh) scale(0.75);
  }
  41.6666666667% {
    -webkit-transform: translateX(-3vw) translateY(7vh) scale(0.47);
            transform: translateX(-3vw) translateY(7vh) scale(0.47);
  }
  45.8333333333% {
    -webkit-transform: translateX(22vw) translateY(-11vh) scale(0.97);
            transform: translateX(22vw) translateY(-11vh) scale(0.97);
  }
  50% {
    -webkit-transform: translateX(41vw) translateY(20vh) scale(0.37);
            transform: translateX(41vw) translateY(20vh) scale(0.37);
  }
  54.1666666667% {
    -webkit-transform: translateX(23vw) translateY(-14vh) scale(0.41);
            transform: translateX(23vw) translateY(-14vh) scale(0.41);
  }
  58.3333333333% {
    -webkit-transform: translateX(43vw) translateY(-30vh) scale(0.34);
            transform: translateX(43vw) translateY(-30vh) scale(0.34);
  }
  62.5% {
    -webkit-transform: translateX(-31vw) translateY(-31vh) scale(0.32);
            transform: translateX(-31vw) translateY(-31vh) scale(0.32);
  }
  66.6666666667% {
    -webkit-transform: translateX(-21vw) translateY(-11vh) scale(0.42);
            transform: translateX(-21vw) translateY(-11vh) scale(0.42);
  }
  70.8333333333% {
    -webkit-transform: translateX(47vw) translateY(11vh) scale(0.29);
            transform: translateX(47vw) translateY(11vh) scale(0.29);
  }
  75% {
    -webkit-transform: translateX(20vw) translateY(-27vh) scale(0.4);
            transform: translateX(20vw) translateY(-27vh) scale(0.4);
  }
  79.1666666667% {
    -webkit-transform: translateX(-47vw) translateY(37vh) scale(0.52);
            transform: translateX(-47vw) translateY(37vh) scale(0.52);
  }
  83.3333333333% {
    -webkit-transform: translateX(0vw) translateY(3vh) scale(0.44);
            transform: translateX(0vw) translateY(3vh) scale(0.44);
  }
  87.5% {
    -webkit-transform: translateX(27vw) translateY(47vh) scale(0.88);
            transform: translateX(27vw) translateY(47vh) scale(0.88);
  }
  91.6666666667% {
    -webkit-transform: translateX(-5vw) translateY(3vh) scale(0.82);
            transform: translateX(-5vw) translateY(3vh) scale(0.82);
  }
  95.8333333333% {
    -webkit-transform: translateX(16vw) translateY(10vh) scale(0.61);
            transform: translateX(16vw) translateY(10vh) scale(0.61);
  }
  100% {
    -webkit-transform: translateX(5vw) translateY(32vh) scale(0.33);
            transform: translateX(5vw) translateY(32vh) scale(0.33);
  }
}
.firefly:nth-child(14) {
  -webkit-animation-name: move14;
          animation-name: move14;
}
.firefly:nth-child(14)::before {
  -webkit-animation-duration: 18s;
          animation-duration: 18s;
}
.firefly:nth-child(14)::after {
  -webkit-animation-duration: 18s, 8910ms;
          animation-duration: 18s, 8910ms;
  -webkit-animation-delay: 0ms, 3832ms;
          animation-delay: 0ms, 3832ms;
}

@-webkit-keyframes move14 {
  0% {
    -webkit-transform: translateX(21vw) translateY(3vh) scale(0.42);
            transform: translateX(21vw) translateY(3vh) scale(0.42);
  }
  4.347826087% {
    -webkit-transform: translateX(-30vw) translateY(-40vh) scale(0.96);
            transform: translateX(-30vw) translateY(-40vh) scale(0.96);
  }
  8.6956521739% {
    -webkit-transform: translateX(-26vw) translateY(-40vh) scale(0.82);
            transform: translateX(-26vw) translateY(-40vh) scale(0.82);
  }
  13.0434782609% {
    -webkit-transform: translateX(-19vw) translateY(-2vh) scale(0.72);
            transform: translateX(-19vw) translateY(-2vh) scale(0.72);
  }
  17.3913043478% {
    -webkit-transform: translateX(-49vw) translateY(36vh) scale(0.31);
            transform: translateX(-49vw) translateY(36vh) scale(0.31);
  }
  21.7391304348% {
    -webkit-transform: translateX(4vw) translateY(-6vh) scale(0.52);
            transform: translateX(4vw) translateY(-6vh) scale(0.52);
  }
  26.0869565217% {
    -webkit-transform: translateX(-16vw) translateY(40vh) scale(0.96);
            transform: translateX(-16vw) translateY(40vh) scale(0.96);
  }
  30.4347826087% {
    -webkit-transform: translateX(15vw) translateY(15vh) scale(0.9);
            transform: translateX(15vw) translateY(15vh) scale(0.9);
  }
  34.7826086957% {
    -webkit-transform: translateX(26vw) translateY(-6vh) scale(0.28);
            transform: translateX(26vw) translateY(-6vh) scale(0.28);
  }
  39.1304347826% {
    -webkit-transform: translateX(9vw) translateY(46vh) scale(0.35);
            transform: translateX(9vw) translateY(46vh) scale(0.35);
  }
  43.4782608696% {
    -webkit-transform: translateX(-25vw) translateY(19vh) scale(0.79);
            transform: translateX(-25vw) translateY(19vh) scale(0.79);
  }
  47.8260869565% {
    -webkit-transform: translateX(-33vw) translateY(46vh) scale(0.74);
            transform: translateX(-33vw) translateY(46vh) scale(0.74);
  }
  52.1739130435% {
    -webkit-transform: translateX(-22vw) translateY(1vh) scale(0.36);
            transform: translateX(-22vw) translateY(1vh) scale(0.36);
  }
  56.5217391304% {
    -webkit-transform: translateX(-49vw) translateY(9vh) scale(0.52);
            transform: translateX(-49vw) translateY(9vh) scale(0.52);
  }
  60.8695652174% {
    -webkit-transform: translateX(-29vw) translateY(39vh) scale(0.7);
            transform: translateX(-29vw) translateY(39vh) scale(0.7);
  }
  65.2173913043% {
    -webkit-transform: translateX(25vw) translateY(31vh) scale(0.34);
            transform: translateX(25vw) translateY(31vh) scale(0.34);
  }
  69.5652173913% {
    -webkit-transform: translateX(19vw) translateY(18vh) scale(0.85);
            transform: translateX(19vw) translateY(18vh) scale(0.85);
  }
  73.9130434783% {
    -webkit-transform: translateX(-25vw) translateY(-25vh) scale(0.71);
            transform: translateX(-25vw) translateY(-25vh) scale(0.71);
  }
  78.2608695652% {
    -webkit-transform: translateX(-46vw) translateY(-32vh) scale(0.77);
            transform: translateX(-46vw) translateY(-32vh) scale(0.77);
  }
  82.6086956522% {
    -webkit-transform: translateX(31vw) translateY(18vh) scale(0.41);
            transform: translateX(31vw) translateY(18vh) scale(0.41);
  }
  86.9565217391% {
    -webkit-transform: translateX(41vw) translateY(-27vh) scale(0.5);
            transform: translateX(41vw) translateY(-27vh) scale(0.5);
  }
  91.3043478261% {
    -webkit-transform: translateX(37vw) translateY(12vh) scale(0.78);
            transform: translateX(37vw) translateY(12vh) scale(0.78);
  }
  95.652173913% {
    -webkit-transform: translateX(-15vw) translateY(-29vh) scale(0.68);
            transform: translateX(-15vw) translateY(-29vh) scale(0.68);
  }
  100% {
    -webkit-transform: translateX(31vw) translateY(30vh) scale(0.96);
            transform: translateX(31vw) translateY(30vh) scale(0.96);
  }
}

@keyframes move14 {
  0% {
    -webkit-transform: translateX(21vw) translateY(3vh) scale(0.42);
            transform: translateX(21vw) translateY(3vh) scale(0.42);
  }
  4.347826087% {
    -webkit-transform: translateX(-30vw) translateY(-40vh) scale(0.96);
            transform: translateX(-30vw) translateY(-40vh) scale(0.96);
  }
  8.6956521739% {
    -webkit-transform: translateX(-26vw) translateY(-40vh) scale(0.82);
            transform: translateX(-26vw) translateY(-40vh) scale(0.82);
  }
  13.0434782609% {
    -webkit-transform: translateX(-19vw) translateY(-2vh) scale(0.72);
            transform: translateX(-19vw) translateY(-2vh) scale(0.72);
  }
  17.3913043478% {
    -webkit-transform: translateX(-49vw) translateY(36vh) scale(0.31);
            transform: translateX(-49vw) translateY(36vh) scale(0.31);
  }
  21.7391304348% {
    -webkit-transform: translateX(4vw) translateY(-6vh) scale(0.52);
            transform: translateX(4vw) translateY(-6vh) scale(0.52);
  }
  26.0869565217% {
    -webkit-transform: translateX(-16vw) translateY(40vh) scale(0.96);
            transform: translateX(-16vw) translateY(40vh) scale(0.96);
  }
  30.4347826087% {
    -webkit-transform: translateX(15vw) translateY(15vh) scale(0.9);
            transform: translateX(15vw) translateY(15vh) scale(0.9);
  }
  34.7826086957% {
    -webkit-transform: translateX(26vw) translateY(-6vh) scale(0.28);
            transform: translateX(26vw) translateY(-6vh) scale(0.28);
  }
  39.1304347826% {
    -webkit-transform: translateX(9vw) translateY(46vh) scale(0.35);
            transform: translateX(9vw) translateY(46vh) scale(0.35);
  }
  43.4782608696% {
    -webkit-transform: translateX(-25vw) translateY(19vh) scale(0.79);
            transform: translateX(-25vw) translateY(19vh) scale(0.79);
  }
  47.8260869565% {
    -webkit-transform: translateX(-33vw) translateY(46vh) scale(0.74);
            transform: translateX(-33vw) translateY(46vh) scale(0.74);
  }
  52.1739130435% {
    -webkit-transform: translateX(-22vw) translateY(1vh) scale(0.36);
            transform: translateX(-22vw) translateY(1vh) scale(0.36);
  }
  56.5217391304% {
    -webkit-transform: translateX(-49vw) translateY(9vh) scale(0.52);
            transform: translateX(-49vw) translateY(9vh) scale(0.52);
  }
  60.8695652174% {
    -webkit-transform: translateX(-29vw) translateY(39vh) scale(0.7);
            transform: translateX(-29vw) translateY(39vh) scale(0.7);
  }
  65.2173913043% {
    -webkit-transform: translateX(25vw) translateY(31vh) scale(0.34);
            transform: translateX(25vw) translateY(31vh) scale(0.34);
  }
  69.5652173913% {
    -webkit-transform: translateX(19vw) translateY(18vh) scale(0.85);
            transform: translateX(19vw) translateY(18vh) scale(0.85);
  }
  73.9130434783% {
    -webkit-transform: translateX(-25vw) translateY(-25vh) scale(0.71);
            transform: translateX(-25vw) translateY(-25vh) scale(0.71);
  }
  78.2608695652% {
    -webkit-transform: translateX(-46vw) translateY(-32vh) scale(0.77);
            transform: translateX(-46vw) translateY(-32vh) scale(0.77);
  }
  82.6086956522% {
    -webkit-transform: translateX(31vw) translateY(18vh) scale(0.41);
            transform: translateX(31vw) translateY(18vh) scale(0.41);
  }
  86.9565217391% {
    -webkit-transform: translateX(41vw) translateY(-27vh) scale(0.5);
            transform: translateX(41vw) translateY(-27vh) scale(0.5);
  }
  91.3043478261% {
    -webkit-transform: translateX(37vw) translateY(12vh) scale(0.78);
            transform: translateX(37vw) translateY(12vh) scale(0.78);
  }
  95.652173913% {
    -webkit-transform: translateX(-15vw) translateY(-29vh) scale(0.68);
            transform: translateX(-15vw) translateY(-29vh) scale(0.68);
  }
  100% {
    -webkit-transform: translateX(31vw) translateY(30vh) scale(0.96);
            transform: translateX(31vw) translateY(30vh) scale(0.96);
  }
}
.firefly:nth-child(15) {
  -webkit-animation-name: move15;
          animation-name: move15;
}
.firefly:nth-child(15)::before {
  -webkit-animation-duration: 17s;
          animation-duration: 17s;
}
.firefly:nth-child(15)::after {
  -webkit-animation-duration: 17s, 8620ms;
          animation-duration: 17s, 8620ms;
  -webkit-animation-delay: 0ms, 742ms;
          animation-delay: 0ms, 742ms;
}

@-webkit-keyframes move15 {
  0% {
    -webkit-transform: translateX(30vw) translateY(29vh) scale(0.57);
            transform: translateX(30vw) translateY(29vh) scale(0.57);
  }
  4.7619047619% {
    -webkit-transform: translateX(-23vw) translateY(-47vh) scale(0.3);
            transform: translateX(-23vw) translateY(-47vh) scale(0.3);
  }
  9.5238095238% {
    -webkit-transform: translateX(33vw) translateY(46vh) scale(0.27);
            transform: translateX(33vw) translateY(46vh) scale(0.27);
  }
  14.2857142857% {
    -webkit-transform: translateX(46vw) translateY(7vh) scale(0.82);
            transform: translateX(46vw) translateY(7vh) scale(0.82);
  }
  19.0476190476% {
    -webkit-transform: translateX(-25vw) translateY(41vh) scale(0.87);
            transform: translateX(-25vw) translateY(41vh) scale(0.87);
  }
  23.8095238095% {
    -webkit-transform: translateX(-46vw) translateY(22vh) scale(0.99);
            transform: translateX(-46vw) translateY(22vh) scale(0.99);
  }
  28.5714285714% {
    -webkit-transform: translateX(4vw) translateY(11vh) scale(0.59);
            transform: translateX(4vw) translateY(11vh) scale(0.59);
  }
  33.3333333333% {
    -webkit-transform: translateX(-43vw) translateY(20vh) scale(0.97);
            transform: translateX(-43vw) translateY(20vh) scale(0.97);
  }
  38.0952380952% {
    -webkit-transform: translateX(25vw) translateY(-18vh) scale(0.79);
            transform: translateX(25vw) translateY(-18vh) scale(0.79);
  }
  42.8571428571% {
    -webkit-transform: translateX(18vw) translateY(-7vh) scale(0.4);
            transform: translateX(18vw) translateY(-7vh) scale(0.4);
  }
  47.619047619% {
    -webkit-transform: translateX(-10vw) translateY(-36vh) scale(1);
            transform: translateX(-10vw) translateY(-36vh) scale(1);
  }
  52.380952381% {
    -webkit-transform: translateX(-4vw) translateY(28vh) scale(0.8);
            transform: translateX(-4vw) translateY(28vh) scale(0.8);
  }
  57.1428571429% {
    -webkit-transform: translateX(-43vw) translateY(50vh) scale(0.99);
            transform: translateX(-43vw) translateY(50vh) scale(0.99);
  }
  61.9047619048% {
    -webkit-transform: translateX(30vw) translateY(-40vh) scale(0.99);
            transform: translateX(30vw) translateY(-40vh) scale(0.99);
  }
  66.6666666667% {
    -webkit-transform: translateX(48vw) translateY(-7vh) scale(0.73);
            transform: translateX(48vw) translateY(-7vh) scale(0.73);
  }
  71.4285714286% {
    -webkit-transform: translateX(18vw) translateY(-19vh) scale(0.47);
            transform: translateX(18vw) translateY(-19vh) scale(0.47);
  }
  76.1904761905% {
    -webkit-transform: translateX(-14vw) translateY(-35vh) scale(0.71);
            transform: translateX(-14vw) translateY(-35vh) scale(0.71);
  }
  80.9523809524% {
    -webkit-transform: translateX(34vw) translateY(17vh) scale(0.97);
            transform: translateX(34vw) translateY(17vh) scale(0.97);
  }
  85.7142857143% {
    -webkit-transform: translateX(48vw) translateY(-29vh) scale(0.88);
            transform: translateX(48vw) translateY(-29vh) scale(0.88);
  }
  90.4761904762% {
    -webkit-transform: translateX(44vw) translateY(46vh) scale(0.99);
            transform: translateX(44vw) translateY(46vh) scale(0.99);
  }
  95.2380952381% {
    -webkit-transform: translateX(22vw) translateY(50vh) scale(0.85);
            transform: translateX(22vw) translateY(50vh) scale(0.85);
  }
  100% {
    -webkit-transform: translateX(-3vw) translateY(-48vh) scale(0.54);
            transform: translateX(-3vw) translateY(-48vh) scale(0.54);
  }
}

@keyframes move15 {
  0% {
    -webkit-transform: translateX(30vw) translateY(29vh) scale(0.57);
            transform: translateX(30vw) translateY(29vh) scale(0.57);
  }
  4.7619047619% {
    -webkit-transform: translateX(-23vw) translateY(-47vh) scale(0.3);
            transform: translateX(-23vw) translateY(-47vh) scale(0.3);
  }
  9.5238095238% {
    -webkit-transform: translateX(33vw) translateY(46vh) scale(0.27);
            transform: translateX(33vw) translateY(46vh) scale(0.27);
  }
  14.2857142857% {
    -webkit-transform: translateX(46vw) translateY(7vh) scale(0.82);
            transform: translateX(46vw) translateY(7vh) scale(0.82);
  }
  19.0476190476% {
    -webkit-transform: translateX(-25vw) translateY(41vh) scale(0.87);
            transform: translateX(-25vw) translateY(41vh) scale(0.87);
  }
  23.8095238095% {
    -webkit-transform: translateX(-46vw) translateY(22vh) scale(0.99);
            transform: translateX(-46vw) translateY(22vh) scale(0.99);
  }
  28.5714285714% {
    -webkit-transform: translateX(4vw) translateY(11vh) scale(0.59);
            transform: translateX(4vw) translateY(11vh) scale(0.59);
  }
  33.3333333333% {
    -webkit-transform: translateX(-43vw) translateY(20vh) scale(0.97);
            transform: translateX(-43vw) translateY(20vh) scale(0.97);
  }
  38.0952380952% {
    -webkit-transform: translateX(25vw) translateY(-18vh) scale(0.79);
            transform: translateX(25vw) translateY(-18vh) scale(0.79);
  }
  42.8571428571% {
    -webkit-transform: translateX(18vw) translateY(-7vh) scale(0.4);
            transform: translateX(18vw) translateY(-7vh) scale(0.4);
  }
  47.619047619% {
    -webkit-transform: translateX(-10vw) translateY(-36vh) scale(1);
            transform: translateX(-10vw) translateY(-36vh) scale(1);
  }
  52.380952381% {
    -webkit-transform: translateX(-4vw) translateY(28vh) scale(0.8);
            transform: translateX(-4vw) translateY(28vh) scale(0.8);
  }
  57.1428571429% {
    -webkit-transform: translateX(-43vw) translateY(50vh) scale(0.99);
            transform: translateX(-43vw) translateY(50vh) scale(0.99);
  }
  61.9047619048% {
    -webkit-transform: translateX(30vw) translateY(-40vh) scale(0.99);
            transform: translateX(30vw) translateY(-40vh) scale(0.99);
  }
  66.6666666667% {
    -webkit-transform: translateX(48vw) translateY(-7vh) scale(0.73);
            transform: translateX(48vw) translateY(-7vh) scale(0.73);
  }
  71.4285714286% {
    -webkit-transform: translateX(18vw) translateY(-19vh) scale(0.47);
            transform: translateX(18vw) translateY(-19vh) scale(0.47);
  }
  76.1904761905% {
    -webkit-transform: translateX(-14vw) translateY(-35vh) scale(0.71);
            transform: translateX(-14vw) translateY(-35vh) scale(0.71);
  }
  80.9523809524% {
    -webkit-transform: translateX(34vw) translateY(17vh) scale(0.97);
            transform: translateX(34vw) translateY(17vh) scale(0.97);
  }
  85.7142857143% {
    -webkit-transform: translateX(48vw) translateY(-29vh) scale(0.88);
            transform: translateX(48vw) translateY(-29vh) scale(0.88);
  }
  90.4761904762% {
    -webkit-transform: translateX(44vw) translateY(46vh) scale(0.99);
            transform: translateX(44vw) translateY(46vh) scale(0.99);
  }
  95.2380952381% {
    -webkit-transform: translateX(22vw) translateY(50vh) scale(0.85);
            transform: translateX(22vw) translateY(50vh) scale(0.85);
  }
  100% {
    -webkit-transform: translateX(-3vw) translateY(-48vh) scale(0.54);
            transform: translateX(-3vw) translateY(-48vh) scale(0.54);
  }
}
@-webkit-keyframes drift {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes drift {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #FFF01F;
  }
}
@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw #FFF01F;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw #FFF01F;
  }
}
